import { useTranslations } from '@qonsoll/translation'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { SCREEN_NAMES } from 'domains/ScreenSlider/__constants__'
import { useCustomerData } from '~/contexts/CustomerData'
import { useMediaQuery } from '~/hooks'

const useScreenSliderNextButtonText = () => {
  // [ADDITIONAL_HOOKS]
  const {
    userEmail,
    isEmailScreenVisible,
    logo,
    color,
    idea,
    isDraftCustomerCreated
  } = useAppDataContext()
  const [{ customerProfileData, draftCustomerData }] = useCustomerData()
  const { t } = useTranslations()
  const isMobile = useMediaQuery('(max-width: 568px)')

  return {
    [SCREEN_NAMES.BOOKING]:
      !isMobile &&
      (userEmail && !isEmailScreenVisible ? t('Schedule meeting') : t('Next')),
    [SCREEN_NAMES.EMAIL]: t('Schedule meeting'),
    [SCREEN_NAMES.LOGO]:
      (!customerProfileData && !isDraftCustomerCreated) ||
      customerProfileData?.logoUrl ||
      draftCustomerData?.logoUrl ||
      !logo
        ? t('Next')
        : t('Save'),
    [SCREEN_NAMES.COLOR]:
      (!customerProfileData && !isDraftCustomerCreated) ||
      customerProfileData?.colorIds?.length ||
      draftCustomerData?.color ||
      !color
        ? t('Next')
        : t('Save'),
    [SCREEN_NAMES.STYLE]:
      (!customerProfileData && !isDraftCustomerCreated) ||
      customerProfileData?.designIds?.length ||
      draftCustomerData?.designId
        ? t('Next')
        : t('Save'),
    [SCREEN_NAMES.IDEA]:
      !idea || customerProfileData?.description || draftCustomerData?.idea
        ? { text: t('Save'), disabled: true }
        : t('Save'),
    [SCREEN_NAMES.SUCCESS]: false
  }
}

export default useScreenSliderNextButtonText
