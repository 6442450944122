import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { useUpdateAppData } from 'domains/App/hooks'
import { EMAIL_REGEX, STATISTIC_COLLECTIONS } from '~/__constants__'
import { getUserEmailValue } from '~/helpers'
import {
  useCreateDraftBooking,
  useCalculatorResultEmailUpdate,
  useIsAppointmentBooked,
  useCreateCustomer,
  useValidateDataForMakingRequest,
  useIncreaseStatisticCounter
} from '~/hooks'
import { useState } from 'react'
import { Notification } from '~/components'
import { useTranslations } from '@qonsoll/translation'

const useHandleWholeBookingProcess = () => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { handleChangeResultModalVisible, handleIsEmailVisible } =
    useUpdateAppData()
  const handleIsAppointmentBooked = useIsAppointmentBooked()
  const handleCreateDraftBooking = useCreateDraftBooking()
  const handleMakingRequestForCreatingCustomer = useCreateCustomer()
  const handleCalculatorResultEmailUpdate = useCalculatorResultEmailUpdate()
  const validateDataForMakingRequest = useValidateDataForMakingRequest()
  const { userEmail, calculatorResults } = useAppDataContext()
  const increaseStatistic = useIncreaseStatisticCounter()

  // [COMPONENT STATE HOOK]
  const [loading, setLoading] = useState(false)

  // [HELPER_FUNCTIONS]
  const handleWholeBookingProcess = async () => {
    /* Getting user email from input directly to prevent bug with disappearing email screen after enter.
     * Or using userEmail from context it can set there from customer of calculator results.*/
    const email = getUserEmailValue() || userEmail

    // Check is email valid on book button click.
    if (!new RegExp(EMAIL_REGEX).test(email)) {
      Notification.error(t('Please enter a valid email address'))
      throw new Error('')
    }

    // Check if we have all data for successful book request.
    if (validateDataForMakingRequest(email) && !loading) {
      setLoading(true)
      try {
        /* Precheck if current appointment haven't been already booked.
           If appointment don't booked - create customer, book meeting and update email in calculator results.
           otherwise - show notification about already booked appointment.
        */
        const isAppointmentBooked = await handleIsAppointmentBooked()
        if (isAppointmentBooked) {
          /* Create customer in case when we have calculatorResults with email
             Or don't have user email in context(case when don't have calculatorResults of have it, but without email).  */
          if (!userEmail || (calculatorResults && userEmail)) {
            // Change special flag in context to prevent removing email screen from slider.
            !userEmail && handleIsEmailVisible(true)

            await handleMakingRequestForCreatingCustomer(email)
            handleCalculatorResultEmailUpdate(email)
          }
          await handleCreateDraftBooking(email)
          handleChangeResultModalVisible(true)
        } else {
          throw new Error(t('Time slot is already busy'))
        }
      } catch (error) {
        Notification.error(error.message)
        setLoading(false)
        throw error
      }

      increaseStatistic({
        field: STATISTIC_COLLECTIONS.BOOKED_MEETING_COUNT_FIELD,
        functionName: 'handleWholeBookingProcess'
      })
      setLoading(false)
    } else {
      throw new Error('')
    }
  }

  return [handleWholeBookingProcess, loading]
}

export default useHandleWholeBookingProcess
