import { useListenTimeslots } from 'domains/TimeSlot/hooks'
import { useMemo } from 'react'
import moment from 'moment'
import { DAY_PARTS } from '~/__constants__'
import { getTimeslotDayPart } from '~/domains/TimeSlot/helpers'

const useTransformTimeslots = ({ meetingDay }) => {
  // [ADDITIONAL HOOKS]
  const [timeslotsData, loading] = useListenTimeslots({ meetingDay })

  // [HELPER FUNCTIONS]
  const allTimeslots = useMemo(() => {
    const result = {}
    if (loading) {
      return result
    }

    timeslotsData?.forEach?.((userTimeSlot) => {
      Object.entries(userTimeSlot)?.forEach(([dayPart, timeslots]) => {
        if (DAY_PARTS.includes(dayPart)) {
          const isTimeslotsAvailable = Boolean(Object.keys(timeslots)?.length)

          Object.keys(timeslots)?.forEach((timeslot) => {
            const isToday =
              moment().format('YYYY-MM-DD') ===
              moment(meetingDay, moment.ISO_8601).format('YYYY-MM-DD')

            const splitTimeRange = timeslot.split('_')
            const timeStart = moment(splitTimeRange[0], moment.ISO_8601)

            let currentTimeSlotDayPart = getTimeslotDayPart(timeslot)

            // eslint-disable-next-line no-prototype-builtins
            if (!result.hasOwnProperty(currentTimeSlotDayPart)) {
              result[currentTimeSlotDayPart] = {}
            }

            if (
              timeStart.isAfter(moment()) ||
              (!isToday && !isTimeslotsAvailable)
            ) {
              // eslint-disable-next-line no-prototype-builtins
              if (result[currentTimeSlotDayPart].hasOwnProperty(timeslot)) {
                result[currentTimeSlotDayPart][timeslot] = [
                  ...result[currentTimeSlotDayPart][timeslot],
                  userTimeSlot._id
                ]
              } else {
                result[currentTimeSlotDayPart][timeslot] = [userTimeSlot._id]
              }
            }
          })
        }
      })
    })

    return result
  }, [timeslotsData, loading])

  return allTimeslots
}

export default useTransformTimeslots
