import { useRef, useState } from 'react'

/**
 *
 * @returns {{isLastSlide: boolean, screenSliderRef: React.MutableRefObject<undefined>, handleChangeSlide: (function(*): *), slickNext: (function(): *), setSlidesCount: (value: (prevState: undefined) => undefined) => void, afterChange: (function(*): void), slidesCount: undefined, currentSlideIndex: number, slickPrev: (function(): *)}}
 */
const useNavigationDotsState = () => {
  // [COMPONENT_STATE_HOOKS]
  const screenSliderRef = useRef()
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [slidesCount, setSlidesCount] = useState()

  // [COMPUTED_PROPERTIES]
  const isLastSlide = slidesCount - 1 === currentSlideIndex

  // [HELPER_FUNCTIONS]
  const slickNext = () => screenSliderRef?.current?.slickNext()

  // function that calls the `slickPrev` method of Slider
  const slickPrev = () => screenSliderRef?.current?.slickPrev()

  const handleChangeSlide = (index) =>
    screenSliderRef?.current?.slickGoTo(index)

  const afterChange = (currentSlide) => setCurrentSlideIndex?.(currentSlide)

  return {
    screenSliderRef,
    currentSlideIndex,
    slickNext,
    slickPrev,
    handleChangeSlide,
    slidesCount,
    setSlidesCount,
    isLastSlide,
    afterChange
  }
}

export default useNavigationDotsState
