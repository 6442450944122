import { useState, useEffect } from 'react'

/**
 *
 * @param query - This is an argument which represents media-width
 * to be compared with real window-width
 * @returns A boolean value: true - if passed media-width matches window-width
 * and vice versa
 */
const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [matches, query])

  return matches
}

export default useMediaQuery
