import styled from 'styled-components'

export const StyleSliderNavigationDotWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  justify-content: center;
`
export const StyleSliderNavigationButtonsWrapper = styled.div`
  display: none;
  top: -16px;
  position: absolute;
  width: 100%;
  z-index: 100;
  justify-content: center;
`
