import { cloneElement, forwardRef } from 'react'

import PropTypes from 'prop-types'
import ReactSlider from 'react-slick'

const Slider = forwardRef((props, ref) => {
  const { settings, children, prevSlideButton, nextSlideButton, ...rest } =
    props

  return (
    <>
      <ReactSlider {...settings} ref={ref} {...rest}>
        {children}
      </ReactSlider>
      {prevSlideButton &&
        cloneElement(prevSlideButton, {
          className: 'previous-slide-button'
        })}
      {nextSlideButton &&
        cloneElement(nextSlideButton, {
          className: 'next-slide-button'
        })}
    </>
  )
})
Slider.propTypes = {
  settings: PropTypes.object,
  children: PropTypes.node,
  prevSlideButton: PropTypes.node,
  nextSlideButton: PropTypes.node
}

export default Slider
