import styled from 'styled-components'

const HeaderStyled = styled.header`
  position: sticky;
  left: 0;
  right: 0;
  top: 0;

  z-index: 10;
  & > div {
    background-color: var(--booking-module-header-bg);
    padding: var(--booking-module-header-padding-y)
      var(--booking-module-header-padding-x);
  }
`

const HeaderDivider = styled.div`
  width: var(--booking-module-bw-sm);
  height: 32px;
  background: var(--booking-module-neutral-lighten-t5);
  margin: 0 28px;
`

export default HeaderStyled
export { HeaderDivider }
