import styled from 'styled-components'

export const StyledRadioButton = styled.label`
  width: inherit;
  background-color: ${({ isSelected }) =>
    isSelected
      ? 'var(--booking-module-radio-btn-primary-bg)'
      : 'var(--booking-module-radio-btn-default-bg)'};
  font-weight: ${({ isSelected }) =>
    isSelected
      ? 'var(--booking-module-radio-btn-primary-font-weight)'
      : 'var(--booking-module-radio-btn-default-font-weight)'};
  display: inline-block;
  border-radius: var(--booking-module-radio-btn-default-border-radius);
  padding: var(--booking-module-radio-btn-default-padding-y)
    var(--booking-module-radio-btn-default-padding-x);
  cursor: pointer;
  margin: 0;
  font-size: var(--booking-module-radio-btn-default-font-size);
  line-height: var(--booking-module-radio-btn-default-line-height);
  color: ${({ isSelected }) =>
    isSelected
      ? 'var(--booking-module-radio-btn-primary-color)'
      : 'var(--booking-module-radio-btn-default-color)'};
  transition: color,background-color 0.4s;
  text-align: center;

  & .inputStyled {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  & > span::after {
      display: block;
      content: attr(title);
      font-weight: bold;
      height: 1px;
      color: transparent;
      overflow: hidden;
      visibility: hidden;
    }
  }

  :hover {
    color: var(--booking-module-radio-btn-primary-color);
    background-color: var(--booking-module-radio-btn-default-color-hover);
    font-weight: var(--booking-module-radio-btn-primary-font-weight);
  }
`
