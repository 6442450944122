import Upload from 'rc-upload'
import styled, { css } from 'styled-components'

export const UploaderWrapper = styled.div`
  margin: 48px 0;
  display: flex;
  border-radius: 50%;
  align-items: center;
  align-content: center;
  justify-content: center;

  @media screen and (max-width: 992px) {
    margin: 24px 0;
  }
`

export const UploaderLogo = styled.img`
  width: 100%;
  height: 100%;
  padding: 8px;
  object-fit: cover;
  border-radius: inherit;
`

export const UploadStyled = styled(Upload)`
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: inherit;
  justify-content: center;
  width: var(--booking-module-uploader-size);
  height: var(--booking-module-uploader-size);
  border: var(--booking-module-uploader-border);
  background-color: var(--booking-module-uploader-bg);
  transition: border-color var(--booking-module-uploader-border-transition);
  ${({ isError }) =>
    isError &&
    css`
      border-color: red;
    `}
  :hover {
    border-color: var(--booking-module-accent);
  }
`
