import { Button, Slider, Title } from 'app/components'
import { cloneElement } from 'react'
import { ResultModal, WarningOverflow } from 'domains/App/components'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { SCREEN_NAMES } from 'domains/ScreenSlider/__constants__'
import ScreenSliderMobileNavigation from 'domains/ScreenSlider/components/ScreenSliderMobileNavigation'
import { useEffect } from 'react'

import { useScreenSliderContext } from 'domains/ScreenSlider/contexts/ScreenSlider/useScreenSlider'
import useGetCalculatorResults from 'domains/App/hooks/get/useGetCalculatorResults'
import { useCustomerData } from '~/contexts/CustomerData'
import useMediaQuery from '../../../../hooks/listen/useMediaQuery'

const ScreenSlider = () => {
  // [ADDITIONAL HOOKS]
  const {
    screenSliderTitle,
    handlePrevScreenSlide,
    PrevScreenButtonIcon,
    nextScreenButtonText,
    handleNextScreenSlide,
    screenSliderRef,
    screenSliderSetting,
    setNumberScreenSlide,
    currentScreen,
    screens,
    handleChangeSlide,
    isLoading,
    ...rest
  } = useScreenSliderContext()
  const [{ customerProfileData }] = useCustomerData()
  const { calculatorResults, isDraftCustomerCreated } = useAppDataContext()
  const isMobile = useMediaQuery('(max-width: 568px)')
  useGetCalculatorResults()

  // [HELPER_FUNCTIONS]
  const onBackButtonClick = () => {
    handleChangeSlide(0)
  }
  // [USE_EFFECTS]
  // get number of slides for screen slider
  useEffect(() => {
    const screenSlidesNumber = document.querySelector(
      '.screen-slide .slick-list .slick-track'
    ).children.length

    setNumberScreenSlide(screenSlidesNumber)
  }, [setNumberScreenSlide, calculatorResults, customerProfileData])

  return (
    <div className="px-0 px-sm-2 container-sm">
      <ResultModal />
      <div className="screen-slider-wrapper">
        <Title className="screen-slider-header" as={isMobile ? 'h2' : 'h1'}>
          {screenSliderTitle}
        </Title>

        <div className="screen-slider-content">
          <Slider
            className="screen-slide"
            prevSlideButton={
              PrevScreenButtonIcon && (
                <Button withIcon circle onClick={handlePrevScreenSlide}>
                  <PrevScreenButtonIcon />
                </Button>
              )
            }
            nextSlideButton={
              nextScreenButtonText && (
                <Button
                  disabled={nextScreenButtonText?.disabled}
                  onClick={handleNextScreenSlide}
                  variant="primary"
                  style={{
                    paddingTop: isLoading ? 24 : 28,
                    paddingBottom: isLoading ? 24 : 28
                  }}>
                  {nextScreenButtonText?.text ?? nextScreenButtonText}
                </Button>
              )
            }
            ref={screenSliderRef}
            settings={screenSliderSetting}
            {...rest}>
            {screens.map((screen) =>
              cloneElement(screen.component, { handleNextScreenSlide })
            )}
          </Slider>
          {![SCREEN_NAMES.BOOKING, SCREEN_NAMES.EMAIL].includes(
            currentScreen.name
          ) &&
            !customerProfileData &&
            !isDraftCustomerCreated && (
              <WarningOverflow onButtonClick={onBackButtonClick} />
            )}
        </div>
        <ScreenSliderMobileNavigation
          onBackButtonClick={onBackButtonClick}
          showBackButton={
            ![SCREEN_NAMES.BOOKING, SCREEN_NAMES.EMAIL].includes(
              currentScreen.name
            ) &&
            !customerProfileData &&
            !isDraftCustomerCreated
          }
          showSkipButton={
            ![SCREEN_NAMES.BOOKING, SCREEN_NAMES.EMAIL].includes(
              currentScreen.name
            )
          }
        />
      </div>
    </div>
  )
}

export default ScreenSlider
