import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { collection, addDoc } from 'firebase/firestore'
import { COLLECTIONS } from '~/__constants__'
import { firestore } from '~/services/fb'

const useCreateDraftBooking = () => {
  // [ADDITIONAL_HOOKS]
  const { forWhomMeetingIsBooked, meetingTime, userEmail } = useAppDataContext()

  return async (providedEmail) => {
    try {
      await addDoc(collection(firestore, COLLECTIONS.DRAFT_BOOKING), {
        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        forWhomMeetingIsBooked,
        meetingTime,
        whoBookedMeeting: providedEmail || userEmail
      })
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}

export default useCreateDraftBooking
