import styled, { css } from 'styled-components'
export const DaySimpleViewStyled = styled.div`
  padding: var(--booking-module-day-view-padding-y)
    var(--booking-module-day-view-padding-x);
  border-radius: var(--booking-module-day-view-border-radius);
  background-color: var(--booking-module-day-view-bg);
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 8px;
  transition: all 0.4s;
  &:hover {
    background-color: var(--booking-module-day-view-primary-hover-bg);
    & > .day-view-items {
      transition: all 0.4s;
      color: var(--booking-module-day-view-white);
    }
  }

  & > .day-view-items {
    width: 100%;
    text-align: center;
    font-family: var(--booking-module-day-view-font-family);
  }
  & > .month-and-day-item {
    font-size: var(--booking-module-day-view-font-size);
    line-height: var(--booking-module-day-view-line-height);
    color: var(--booking-module-day-view-color-lighten-t1);
  }
  & > .date-item {
    font-size: var(--booking-module-day-view-primary-font-size);
    line-height: var(--booking-module-day-view-primary-line-height);
    padding: var(--booking-module-day-view-padding-y) 0;
    color: var(--booking-module-day-view-color);
    font-weight: var(--booking-module-day-view-font-weight);
  }

  ${(props) => {
    if (props?.isSelected) {
      return css`
        & > .day-view-items {
          color: var(--booking-module-day-view-white);
        }
        background-color: var(--booking-module-day-view-primary-bg);
      `
    } else if (props?.isDisable) {
      return css`
        & > .day-view-items {
          color: var(--booking-module-day-view-color-lighten-t5);
        }
        background-color: transparent;
        border: var(--booking-module-bw-md) solid
          var(--booking-module-day-view-color-lighten-t5);
        cursor: not-allowed;
        :hover {
          transition: all 0.4s;
          background-color: transparent;
          & > .day-view-items {
            transition: all 0.4s;
            color: var(--booking-module-day-view-color-lighten-t5);
          }
        }
      `
    }
  }}
`
