import { doc, getDoc } from 'firebase/firestore'
import { COLLECTIONS } from '~/__constants__'
import { firestore } from '~/services/fb'

const isAppointmentBooked = async ({
  forWhomMeetingIsBooked,
  meetingTime,
  meetingDay,
  dayPart
}) => {
  const timeslotsByUserOnSomeDayDocument = await getDoc(
    doc(
      firestore,
      COLLECTIONS.TIMESLOTS_COLLECTION,
      meetingDay,
      COLLECTIONS.USERS_TIMESLOTS_COLLECTION,
      forWhomMeetingIsBooked
    )
  )

  const timeslotsByUserOnSomeDayData =
    timeslotsByUserOnSomeDayDocument.exists &&
    timeslotsByUserOnSomeDayDocument.data()

  // Check if timeslot is already busy
  return timeslotsByUserOnSomeDayData[dayPart][meetingTime] || false
}

export default isAppointmentBooked
