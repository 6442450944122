import PropTypes from 'prop-types'
import { DaySimpleViewStyled } from './DaySimpleView.styled'

const DaySimpleView = (props) => {
  const { day, month, date, isSelected, isDisable, onClick } = props

  return (
    <DaySimpleViewStyled
      onClick={isDisable ? undefined : onClick}
      isDisable={isDisable}
      isSelected={isSelected}>
      <div className="day-view-items month-and-day-item">{month}</div>
      <div className="day-view-items date-item">{date} </div>
      <div className="day-view-items month-and-day-item">{day}</div>
    </DaySimpleViewStyled>
  )
}

DaySimpleView.propTypes = {
  day: PropTypes.string,
  month: PropTypes.string,
  date: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isDisable: PropTypes.bool
}

export default DaySimpleView
