import { AppDataProvider } from 'domains/App/contexts'
import { Header } from 'app/components'

import { ScreenSlider } from 'app/domains/ScreenSlider/components'
import { ScreenSliderProvider } from 'domains/ScreenSlider/contexts'
import { CustomerDataProvider } from '~/contexts/CustomerData'
import { useLanguageAccordingToRoute } from './hooks'

function App() {
  useLanguageAccordingToRoute()
  return (
    <CustomerDataProvider>
      <AppDataProvider>
        <ScreenSliderProvider>
          <Header />
          <ScreenSlider />
        </ScreenSliderProvider>
      </AppDataProvider>
    </CustomerDataProvider>
  )
}

export default App
