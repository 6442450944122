import PropTypes from 'prop-types'
import { StyledRadioButton } from './RadioButton.styled'

const RadioButton = (props) => {
  const { isSelected, onClick, text } = props
  // [COMPUTER PROPERTIES]
  return (
    <StyledRadioButton isSelected={isSelected} onClick={onClick}>
      <input className="inputStyled" type="radio" />
      <span title={text}>{text}</span>
    </StyledRadioButton>
  )
}

RadioButton.propTypes = {
  text: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
}

export default RadioButton
