import styled from 'styled-components'

export const ScreenSliderModileNavigationWrapper = styled.div`
  display: flex;
  background: var(--booking-module-bg-primary);
  position: sticky;
  width: 100%;
  padding: 16px;
  right: 16px;
  left: 0;
  bottom: 0;
  z-index: 2;
`

export const ScreenSliderModileNavigationWrapperShadow = styled.div`
  box-shadow: 0 32px 24px 64px var(--booking-module-bg-primary);
  position: absolute;
  top: 0;
  width: 100%;
`

export const buttonStyles = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  zIndex: 999
}
