import styled from 'styled-components'

export const DaySelectWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const GradientLeft = styled.div`
  pointer-events: none;
  position: absolute;
  width: var(--booking-module-day-select-gradient-width);
  height: var(--booking-module-day-select-gradient-height);
  top: 0;
  left: 0;
  right: 0;
  background: var(--booking-module-day-select-gradient-left-bg);
`

export const GradientRight = styled.div`
  pointer-events: none;
  position: absolute;
  width: var(--booking-module-day-select-gradient-width);
  height: var(--booking-module-day-select-gradient-height);
  top: 0;
  right: 0;
  background: var(--booking-module-day-select-gradient-right-bg);
`
