const SLIDER_SETTINGS = {
  infinite: false,
  slidesToShow: 7,
  swipeToSlide: true,
  centerMode: true,
  responsive: [
    {
      breakpoint: 4000,
      settings: {
        slidesToShow: 7,
        className: 'center',
        centerPadding: 0
      }
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 5,
        className: 'center',
        centerPadding: 0
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 7,
        className: 'center',
        centerPadding: 0
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 5,
        className: 'center',
        centerPadding: 0
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
        className: 'center',
        centerPadding: '50px'
      }
    }
  ]
}

export default SLIDER_SETTINGS
