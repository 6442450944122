import { useTranslations } from '@qonsoll/translation'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { doc, updateDoc } from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { COLLECTIONS, CUSTOMER_ID_SEARCH_PARAM } from '~/__constants__'
import { Notification } from '~/components'
import useQuery from '~/hooks/useQuery'
import { firestore } from '~/services/fb'

const useDesignSave = () => {
  // [ADDITIONAL_HOOKS]
  const { styleId } = useAppDataContext()
  const customerId = useQuery().get(CUSTOMER_ID_SEARCH_PARAM)
  const { t } = useTranslations()

  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [HELPER_FUNCTIONS]
  const handleUpdateCustomerDesign = useCallback(async () => {
    try {
      setLoading(true)
      await updateDoc(doc(firestore, COLLECTIONS.DRAFT_CUSTOMERS, customerId), {
        designId: styleId
      })

      Notification.success(t('Design successfully saved'))
    } catch (error) {
      console.error('Error in useDesignSave', error)
      Notification.error(t('Error occurred while design saving'))
    } finally {
      setLoading(false)
    }
  }, [styleId, customerId])

  return [handleUpdateCustomerDesign, loading]
}

export default useDesignSave
