import { useTranslations } from '@qonsoll/translation'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { Notification } from '~/components'

const useValidateDataForMakingRequest = () => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { meetingTime, forWhomMeetingIsBooked, userEmail } = useAppDataContext()

  const checkIfAllDataAreAvailable = (providedEmail) =>
    forWhomMeetingIsBooked !== null &&
    meetingTime !== null &&
    providedEmail !== null

  return (email) => {
    if (checkIfAllDataAreAvailable(email || userEmail)) {
      return true
    } else {
      Notification.error(t('Not enough data to book meeting'))
      return false
    }
  }
}

export default useValidateDataForMakingRequest
