import { useTranslations } from '@qonsoll/translation'
import PropTypes from 'prop-types'
import { Button } from '~/components'
import { useScreenSliderContext } from 'domains/ScreenSlider/contexts/ScreenSlider/useScreenSlider'
import {
  buttonStyles,
  ScreenSliderModileNavigationWrapper,
  ScreenSliderModileNavigationWrapperShadow
} from './ScreenSliderMobileNavigation.styled'

const ScreenSliderMobileNavigation = (props) => {
  const { showBackButton, showSkipButton, onBackButtonClick } = props
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const {
    nextScreenButtonText,
    handleNextScreenSlide,
    slickNext,
    screenSliderCurrentSlide,
    numberScreenSlides
  } = useScreenSliderContext()

  // [COMPUTED_PROPERTIES]
  const onButtonClick =
    (showBackButton && onBackButtonClick) || handleNextScreenSlide

  const isNotLastSlide =
    !!screenSliderCurrentSlide &&
    screenSliderCurrentSlide < numberScreenSlides - 1

  const isSkipButtonVisible =
    isNotLastSlide && showSkipButton && !showBackButton

  return (
    <ScreenSliderModileNavigationWrapper className=" d-lg-none">
      <ScreenSliderModileNavigationWrapperShadow />
      {isSkipButtonVisible && (
        <Button
          variant="ghost"
          onClick={slickNext}
          style={{
            ...buttonStyles,
            marginRight: 16
          }}>
          {t('Skip')}
        </Button>
      )}
      {nextScreenButtonText && (
        <Button
          onClick={onButtonClick}
          variant="primary"
          disabled={nextScreenButtonText?.disabled}
          style={buttonStyles}>
          {showBackButton
            ? t('Go to booking screen')
            : nextScreenButtonText?.text || nextScreenButtonText}
        </Button>
      )}
    </ScreenSliderModileNavigationWrapper>
  )
}

ScreenSliderMobileNavigation.propTypes = {
  showBackButton: PropTypes.bool,
  showSkipButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func
}

export default ScreenSliderMobileNavigation
