import { ScreenSliderContext } from 'app/domains/ScreenSlider/contexts/ScreenSlider/ScreenSliderContext.js'
import { useContext } from 'react'

const useScreenSliderContext = () => {
  const context = useContext(ScreenSliderContext)

  if (context === undefined) {
    throw new Error(
      'useScreenSliderContext must be used within a ScreenSliderContext.Provider'
    )
  }

  return context
}

export { useScreenSliderContext }
