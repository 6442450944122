import styled from 'styled-components'

export const StyleSliderWrapper = styled.div`
  & {
    position: relative;
    flex: 1;
  }
  .style-select .slick-list {
    overflow-y: hidden;
    overflow-x: clip;
  }
  .style-select .slick-active.slick-center img {
    transform: scale(1.2);
    transition: all 0.4s;
    padding: 8px;
    border: var(--booking-module-bw-xxl) solid var(--booking-module-accent);
    border-radius: var(--booking-module-br-xxxl);
    opacity: 1;
  }

  .style-select
    .slick-slide:not(.slick-active.slick-center)
    .style-select-image-wrapper
    img {
    transform: scale(0.8) translateY(20%);
  }

  .style-select img {
    object-fit: contain;
    transition: all 0.4s;

    border: var(--booking-module-bw-xxl) solid transparent;
    border-radius: var(--booking-module-br-xxxl);
    transform: scale(0.8);
    max-width: 100%;
    max-height: 100%;
    opacity: 0.64;
    transform-origin: top center;
  }

  .style-select .style-select-image-wrapper {
    padding: 0 30px;
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 992px) {
    .style-select .slick-active.slick-center img {
      padding: 0;
      border: var(--booking-module-bw-xl) solid var(--booking-module-accent);
      border-radius: var(--booking-module-br-xl);
    }
    .style-select .style-select-image-wrapper {
      padding-left: 4px;
      padding-right: 4px;
      display: unset;
    }
    .style-select img {
      border-radius: var(--booking-module-br-xl);
      border: var(--booking-module-bw-xl) solid transparent;
    }
  }
`
