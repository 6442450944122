import { useTranslations } from '@qonsoll/translation'
import moment from 'moment'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import checkLanguageAvailability from '../../../helpers/checkLanguageAvailability'

const useLanguageAccordingToRoute = () => {
  const { setCurrentLanguage, language } = useTranslations()
  const { languageID } = useParams()

  useEffect(() => {
    const validLanguage = checkLanguageAvailability(languageID)
    if (validLanguage !== language) {
      moment.locale(validLanguage)
      setCurrentLanguage(validLanguage)
    }
  }, [languageID, language])
}

export default useLanguageAccordingToRoute
