import { useTranslations } from '@qonsoll/translation'
import { Segment, Text, Title } from '~/components'
import { capitalizeFirstLetter, checkPhaseOfDay } from '~/helpers'
import PropTypes from 'prop-types'
import { DaySelect } from '../../domains/Day/components'
import { TimeSlotSelect } from '../../domains/TimeSlot/components'
import { DAY_PARTS } from '~/__constants__'
import { useUpdateAppData } from '~/domains/App/hooks/post'
import { useMediaQuery } from '~/hooks'
import { useAppDataContext } from '~/domains/App/contexts/AppData/useAppData'

const ScheduleMeetingScreen = (props) => {
  const { handleNextScreenSlide } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  const { timeslots, dayPart } = useAppDataContext()
  const { handleChangeDayPart } = useUpdateAppData()
  const isMobile = useMediaQuery('(max-width: 568px)')

  return (
    <>
      {!isMobile && (
        <div className="row justify-content-center px-4">
          <div className="col-12 mb-4 mb-lg-5 d-lg-flex flex-lg-column align-items-lg-center p-0">
            <Title as="h5">{t('Choose date')}</Title>
            <Text secondary>
              {t('Choose date to get information about free timeslots.')}
            </Text>
          </div>
        </div>
      )}

      <div className="row justify-content-center mb-lg-3 mb-3 mb-sm-0">
        <div className="col-12 col-xl-9 mb-2 align-items-center ">
          <DaySelect />
        </div>
      </div>

      {!isMobile && (
        <div className="row justify-content-center px-4">
          <div className="col-12 mb-3 mb-lg-4 d-lg-flex flex-lg-column align-items-lg-center p-0">
            <Title as="h5">{t('Choose timeslot')}</Title>
            <Text secondary>
              {t('Choose date to get information about free timeslots.')}
            </Text>
          </div>
        </div>
      )}

      {isMobile ? (
        <div
          style={{
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}>
          {DAY_PARTS.map(
            (dayPart, index) =>
              !!Object.keys(timeslots?.[dayPart] || {})?.length && (
                <>
                  <div
                    className={`px-4 justify-content-center d-flex ${
                      index === DAY_PARTS.length - 1 ? 'pb-5' : 'mb-2'
                    } mb-sm-0 `}>
                    <div>
                      <Title as="h6" secondary className="px-2">
                        {t(capitalizeFirstLetter(dayPart.toLowerCase()))}
                      </Title>

                      <TimeSlotSelect
                        timeslots={Object.keys(timeslots[dayPart]).sort()}
                        dayPart={dayPart}
                        onTimeSlotSelect={() => {
                          isMobile &&
                            new Promise((resolve) =>
                              setTimeout(() => {
                                handleNextScreenSlide()
                                resolve()
                              }, 10)
                            )
                        }}
                      />
                    </div>
                  </div>
                </>
              )
          )}
          {DAY_PARTS.every(
            (dayPart) => !Object.keys(timeslots?.[dayPart] || {})?.length
          ) && (
            <div className="d-flex px-4 justify-content-center pb-5 mb-sm-0">
              <Text secondary>{t('There are no available time slots.')}</Text>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="d-flex px-3 mb-4 justify-content-center ">
            <Segment
              options={DAY_PARTS.map((dayPart) => ({
                label: t(capitalizeFirstLetter(dayPart.toLowerCase())),
                value: dayPart
              }))}
              defaultValue={checkPhaseOfDay()}
              onChange={handleChangeDayPart}
            />
          </div>
          <div className="d-flex px-4 justify-content-center pb-5 mb-sm-0">
            {Object.keys(timeslots?.[dayPart] || {})?.length ? (
              <TimeSlotSelect
                timeslots={Object.keys(timeslots?.[dayPart]).sort()}
              />
            ) : (
              <Text secondary>{t('There are no available time slots.')}</Text>
            )}
          </div>
        </>
      )}
    </>
  )
}

ScheduleMeetingScreen.propTypes = {
  handleNextScreenSlide: PropTypes.func
}

export default ScheduleMeetingScreen
