import { useTranslations } from '@qonsoll/translation'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { useUpdateAppData } from 'domains/App/hooks'
import { doc, updateDoc } from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { COLLECTIONS, CUSTOMER_ID_SEARCH_PARAM } from '~/__constants__'
import { Notification } from '~/components'
import useQuery from '~/hooks/useQuery'
import { firestore } from '~/services/fb'

const useDescriptionSave = () => {
  // [ADDITIONAL_HOOKS]
  const { idea } = useAppDataContext()
  const customerId = useQuery().get(CUSTOMER_ID_SEARCH_PARAM)
  const { t } = useTranslations()
  const { handleIsSuccessScreenVisible } = useUpdateAppData()

  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [HELPER_FUNCTIONS]
  const handleUpdateCustomerDescription = useCallback(async () => {
    try {
      handleIsSuccessScreenVisible(true)
      setLoading(true)
      if (!idea) {
        throw new Error('no description')
      }

      await updateDoc(doc(firestore, COLLECTIONS.DRAFT_CUSTOMERS, customerId), {
        idea
      })

      Notification.success(t('Description successfully saved'))
    } catch (error) {
      console.error('Error in useDescriptionSave', error)
      const errorMessage =
        error.message === 'no description'
          ? 'Write description for your project'
          : 'Error occurred while description saving'
      Notification.error(t(errorMessage))
    } finally {
      setLoading(false)
    }
  }, [idea, customerId])

  return [handleUpdateCustomerDescription, loading]
}

export default useDescriptionSave
