const useScreenSliderConfig = (afterChange) => {
  const animationSpeed = 500
  return {
    dots: false,
    infinite: false,
    speed: animationSpeed,
    draggable: false,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // workaround to fix bug with changing current screen on save button click
    beforeChange: (current, next) => {
      setTimeout(() => afterChange(next), animationSpeed)
    }
  }
}

export default useScreenSliderConfig
