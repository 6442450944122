import styled from 'styled-components'

const ColorViewWrapper = styled.div`
  padding: 4px;
  cursor: pointer;
  border: var(--booking-module-bw-xxl) solid
    ${({ isSelected }) =>
      isSelected
        ? 'var(--booking-module-color-view-selected-bc)'
        : 'transparent'};
  border-radius: 50%;
  &:hover {
    border-color: var(--booking-module-color-view-hover-bc);
  }
  transition: all 0.14s;

  @media screen and (max-width: 568px) {
    border-width: var(--booking-module-bw-lg);
  }
`

const ColorViewStyled = styled.div`
  background: ${({ color }) => color};

  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export { ColorViewStyled, ColorViewWrapper }
