import { useEffect } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { CALCULATOR_RESULT_SEARCH_PARAM, COLLECTIONS } from '~/__constants__'
import useQuery from '~/hooks/useQuery'
import { firestore } from '~/services/fb'
import useUpdateAppData from 'domains/App/hooks/post/useUpdateAppData'

const useGetCalculatorResults = () => {
  // [ADDITIONAL_HOOKS]
  const calculatorResultId = useQuery().get(CALCULATOR_RESULT_SEARCH_PARAM)
  const { handleChangeCalculatorResults } = useUpdateAppData()

  // [USE_EFFECTS]
  useEffect(() => {
    const fetchCalculatorResults = async () => {
      try {
        const docRef = doc(
          firestore,
          COLLECTIONS.CALCULATOR_RESULTS,
          calculatorResultId
        )
        const snapshot = await getDoc(docRef)
        const calculatorResults = snapshot?.data()
        handleChangeCalculatorResults(calculatorResults)
      } catch (error) {
        console.error('ERROR in fetching calculator results', error)
      }
    }
    calculatorResultId && fetchCalculatorResults()
  }, [calculatorResultId])
}

export default useGetCalculatorResults
