import { useTranslations } from '@qonsoll/translation'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { doc, updateDoc } from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { COLLECTIONS, CUSTOMER_ID_SEARCH_PARAM } from '~/__constants__'
import { Notification } from '~/components'
import useQuery from '~/hooks/useQuery'
import { firestore } from '~/services/fb'

const useColorSave = () => {
  // [ADDITIONAL_HOOKS]
  const { color } = useAppDataContext()
  const customerId = useQuery().get(CUSTOMER_ID_SEARCH_PARAM)
  const { t } = useTranslations()

  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [HELPER_FUNCTIONS]
  const handleUpdateCustomerColor = useCallback(async () => {
    try {
      if (!color) {
        return
      }
      setLoading(true)

      await updateDoc(doc(firestore, COLLECTIONS.DRAFT_CUSTOMERS, customerId), {
        color
      })

      Notification.success(t('Color successfully saved'))
    } catch (error) {
      console.error('Error in useColorSave', error)
      Notification.error(t('Error occurred while color saving'))
    } finally {
      setLoading(false)
    }
  }, [color, customerId])

  return [handleUpdateCustomerColor, loading]
}

export default useColorSave
