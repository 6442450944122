import { useEffect } from 'react'
import { useCustomerData } from '~/contexts/CustomerData/useCustomerData'
import { APP_DATA_REDUCER_CASES } from 'domains/App/__constants__'

const useSyncCustomerAndAppData = (appDataDispatch) => {
  // [ADDITIONAL_HOOKS]
  const [{ customerProfileData, colorData, customerData, draftCustomerData }] =
    useCustomerData()

  // [USE_EFFECTS]
  useEffect(() => {
    if (customerProfileData) {
      let data = {}
      if (customerProfileData?.logoUrl) {
        data.logo = customerProfileData?.logoUrl
      }
      if (customerProfileData?.logoFormat) {
        data.logoFormat = customerProfileData?.logoFormat
      }
      if (customerProfileData?.description) {
        data.idea = customerProfileData?.description
      }
      if (customerProfileData?.designIds?.[0]) {
        data.styleId = customerProfileData?.designIds?.[0]
      }

      appDataDispatch({
        type: APP_DATA_REDUCER_CASES.SET_FROM_CUSTOMER_PROFILE,
        payload: data
      })
    }
  }, [customerProfileData])
  useEffect(() => {
    if (colorData) {
      appDataDispatch({
        type: APP_DATA_REDUCER_CASES.SET_FROM_CUSTOMER_PROFILE,
        payload: {
          color: { hex: colorData.hex, rgba: colorData.rgba }
        }
      })
    }
  }, [colorData])
  useEffect(() => {
    if (customerData?.email) {
      appDataDispatch({
        type: APP_DATA_REDUCER_CASES.SET_FROM_CUSTOMER_PROFILE,
        payload: {
          userEmail: customerData.email
        }
      })
    }
  }, [customerData])
  useEffect(() => {
    if (draftCustomerData) {
      appDataDispatch({
        type: APP_DATA_REDUCER_CASES.SET_IS_DRAFT_CUSTOMER_CREATED,
        payload: true
      })
      let data = {}
      if (draftCustomerData?.logoUrl) {
        data.logo = draftCustomerData?.logoUrl
      }
      if (draftCustomerData?.logoFormat) {
        data.logoFormat = draftCustomerData?.logoFormat
      }
      if (draftCustomerData?.description) {
        data.idea = draftCustomerData?.idea
      }
      if (draftCustomerData?.designId) {
        data.styleId = draftCustomerData?.designId
      }

      appDataDispatch({
        type: APP_DATA_REDUCER_CASES.SET_FROM_CUSTOMER_PROFILE,
        payload: data
      })
    }
  }, [draftCustomerData])
}

export default useSyncCustomerAndAppData
