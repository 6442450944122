import { RadioButton } from '~/components'
import moment from 'moment'
import { useMemo } from 'react'
import { useUpdateAppData } from 'domains/App/hooks/post'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import PropTypes from 'prop-types'
import { DEFAULT_PAUSE_IN_TIMESLOTS } from '~/__constants__'

const TimeSlotSelectItem = (props) => {
  const { range, dayPart, onSelect } = props

  // [ADDITIONAL HOOKS]
  const { handleChooseMeetingTime } = useUpdateAppData()
  const { meetingTime } = useAppDataContext()

  // [COMPUTED PROPERTIES]
  const time = useMemo(() => {
    const [start, end] = range.split('_')
    const timeStart = moment(start, moment.ISO_8601).format('HH:mm')
    const timeEnd = moment(end, moment.ISO_8601)
      .subtract(
        DEFAULT_PAUSE_IN_TIMESLOTS.amount,
        DEFAULT_PAUSE_IN_TIMESLOTS.unit
      )
      .format('HH:mm')

    return `${timeStart} - ${timeEnd}`
  }, [range])

  const isSelected = meetingTime === range

  return (
    <RadioButton
      text={time}
      onClick={() => {
        handleChooseMeetingTime(range, dayPart)
        onSelect?.()
      }}
      isSelected={isSelected}
    />
  )
}

TimeSlotSelectItem.propTypes = {
  range: PropTypes.string.isRequired,
  dayPart: PropTypes.string,
  onSelect: PropTypes.func
}
export default TimeSlotSelectItem
