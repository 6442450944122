import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { useGetScreens } from 'domains/ScreenSlider/hooks/get'
import useScreenSliderConfig from 'domains/ScreenSlider/hooks/transform/useScreenSliderConfig'
import useScreenSliderNextButtonFunction from 'domains/ScreenSlider/hooks/transform/useScreenSliderNextButtonFunction'
import useScreenSliderNextButtonText from 'domains/ScreenSlider/hooks/transform/useScreenSliderNextButtonText'
import useScreenSliderTitle from 'domains/ScreenSlider/hooks/transform/useScreenSliderTitle'
import { useMemo } from 'react'
import { useCustomerData } from '~/contexts/CustomerData'
import { useNavigationDotsState } from '~/hooks'
import { ReactComponent as LeftArrow } from '~/assets/leftArrow.svg'
import { ReactComponent as LoadingIcon } from '~/assets/loading.svg'
import { SCREEN_NAMES } from 'domains/ScreenSlider/__constants__'

const useTransformScreenSlider = () => {
  // [ADDITIONAL_HOOKS]
  const [{ customerProfileData }] = useCustomerData()
  const { isDraftCustomerCreated } = useAppDataContext()
  const {
    screenSliderRef,
    afterChange,
    handleChangeSlide,
    slickNext,
    slickPrev,
    currentSlideIndex,
    setSlidesCount,
    slidesCount
  } = useNavigationDotsState()

  const { screens, currentScreen } = useGetScreens(currentSlideIndex)

  //settings for slider
  const screenSliderSetting = useScreenSliderConfig(afterChange)

  // get text for next button
  const nextButtonText = useScreenSliderNextButtonText()

  const screenSliderTitle = useScreenSliderTitle(
    currentScreen.title ?? currentScreen.name
  )

  const { nextButtonFunction, nextButtonLoadings } =
    useScreenSliderNextButtonFunction()

  // [COMPUTED_PROPERTIES]
  const isLoading = nextButtonLoadings[currentScreen.name]
  const PrevScreenButtonIcon = currentSlideIndex ? LeftArrow : null
  const nextScreenButtonText = useMemo(
    () =>
      isLoading ? (
        <LoadingIcon className="spin" fill="var(--booking-module-white)" />
      ) : (
        nextButtonText[currentScreen.name]
      ),
    [isLoading, currentScreen.name, nextButtonText]
  )

  // [HELPER FUNCTIONS]
  const handleNextScreenSlide = async () => {
    try {
      // This condition need to implement following logic on click next aka save button
      if (
        (typeof nextButtonFunction[currentScreen.name] === 'boolean' ||
          (!customerProfileData && !isDraftCustomerCreated)) &&
        ![SCREEN_NAMES.BOOKING, SCREEN_NAMES.EMAIL].includes(currentScreen.name)
      ) {
        return slickNext?.()
      }
      // Call appropriate function for current screen
      if (
        typeof nextButtonFunction[currentScreen.name] !== 'boolean' &&
        !isLoading
      ) {
        await nextButtonFunction?.[currentScreen.name]?.()
      }
      slickNext?.()
    } catch (e) {
      console.error(e)
    }
  }

  return {
    screenSliderRef,
    screenSliderCurrentSlide: currentSlideIndex,
    screenSliderSetting,
    handleNextScreenSlide,
    handlePrevScreenSlide: slickPrev,
    handleChangeSlide,
    numberScreenSlides: slidesCount,
    setNumberScreenSlide: setSlidesCount,
    screenSliderTitle,
    PrevScreenButtonIcon,
    nextScreenButtonText,
    isLoading,
    screens,
    slickNext,
    currentScreen
  }
}

export default useTransformScreenSlider
