import { useMemo } from 'react'
import { useMediaQuery } from '~/hooks'

const useGetExtraSlidesOnMedia = () => {
  // [ADDITIONAL_HOOKS]
  const sevenDayItemsInSlider = useMediaQuery(
    '(min-width: 769px) and (max-width: 992px), (min-width: 1500px)'
  )
  const fiveDayItemsInSlider = useMediaQuery(
    '(min-width: 576px) and (max-width: 768px), (min-width: 992px) and (max-width: 1500px)'
  )
  const treeDayItemsInSlider = useMediaQuery(
    '(min-width: 0px) and (max-width: 576px)'
  )

  return useMemo(() => {
    if (sevenDayItemsInSlider) {
      return 6
    }
    if (fiveDayItemsInSlider) {
      return 4
    }

    return 2
  }, [treeDayItemsInSlider, fiveDayItemsInSlider, sevenDayItemsInSlider])
}

export default useGetExtraSlidesOnMedia
