import { CustomerDataContext } from './CustomerDataContext'

import { useContext } from 'react'

const useCustomerData = () => {
  const context = useContext(CustomerDataContext)

  if (context === undefined) {
    throw new Error(
      'useCustomer must be used within a CustomerContext.Provider'
    )
  }

  return context
}

export { useCustomerData }
