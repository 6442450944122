import moment from 'moment'
import { DAY_PARTS } from '~/__constants__'

const AFTERNOON_TIME = 18
const MORNING_TIME = 12

const getTimeslotDayPart = (timeslot, isUTC) => {
  const splitTimeRange = timeslot.split('_')
  let timeStart = moment(splitTimeRange[0], moment.ISO_8601)

  if (isUTC) {
    timeStart = timeStart.utc()
  }
  const timeslotStartTime = timeStart.get('hours')

  let currentTimeSlotDayPart

  if (timeStart.isSameOrAfter(moment(), 'day'))
    if (timeslotStartTime < MORNING_TIME) {
      currentTimeSlotDayPart = DAY_PARTS[0]
    } else if (timeslotStartTime < AFTERNOON_TIME) {
      currentTimeSlotDayPart = DAY_PARTS[1]
    } else {
      currentTimeSlotDayPart = DAY_PARTS[2]
    }
  return currentTimeSlotDayPart
}

export default getTimeslotDayPart
