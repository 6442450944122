import PropTypes from 'prop-types'
import { cloneElement } from 'react'
import cn from 'classnames'
import styles from './Button.module.css'

/**
 * It returns a button element with a className that generated by passed props: primary and circle.
 *
 * @returns A button element with the className of btn, btn-primary (optional) and btn-circle (optional).
 */
const Button = (props) => {
  const {
    children,
    circle,
    variant,
    withIcon,
    onClick,
    className,
    style,
    disabled
  } = props

  // [COMPUTED PROPERTIES]
  // get class name by passed props
  const btnVariant = disabled ? 'disabled' : variant
  const computedClassName = cn({
    [styles.btn]: true,
    [className]: className,
    [styles[`btn-${btnVariant}`]]: variant || disabled,
    [styles['btn-circle']]: circle
  })

  return (
    <button
      onClick={!disabled ? onClick : undefined}
      className={computedClassName}
      style={style}>
      {withIcon ? cloneElement(children, { className: 'icon' }) : children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  variant: PropTypes.oneOf(['primary', 'white', 'link', 'ghost']),
  className: PropTypes.string,
  circle: PropTypes.bool,
  withIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object
}

export default Button
