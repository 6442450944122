import { APP_DATA_REDUCER_CASES } from 'app/domains/App/__constants__'

const appDataReducer = (state, action) => {
  switch (action.type) {
    // case for set meeting time
    case APP_DATA_REDUCER_CASES.SET_MEETING_TIME: {
      return {
        ...state,
        meetingTime: action?.payload?.meetingTime,
        forWhomMeetingIsBooked: action?.payload?.forWhomMeetingIsBooked
      }
    }
    case APP_DATA_REDUCER_CASES.SET_FROM_CUSTOMER_PROFILE: {
      return {
        ...state,
        ...action.payload
      }
    }
    // case for set app`s logo
    case APP_DATA_REDUCER_CASES.SET_LOGO: {
      return {
        ...state,
        logo: action?.payload.base64,
        logoFormat: action?.payload.fileExtension
      }
    }
    // case for set app`s colors
    case APP_DATA_REDUCER_CASES.SET_COLORS: {
      return {
        ...state,
        color: action?.payload
      }
    }
    // case for set app`s idea
    case APP_DATA_REDUCER_CASES.SET_IDEA: {
      return {
        ...state,
        idea: action?.payload
      }
    }
    // case for set app`s style
    case APP_DATA_REDUCER_CASES.SET_STYLE: {
      return {
        ...state,
        styleId: action?.payload
      }
    }

    // case for set app`s day part
    case APP_DATA_REDUCER_CASES.SET_DAY_PART: {
      return {
        ...state,
        dayPart: action?.payload
      }
    }

    // case for set app`s day part
    case APP_DATA_REDUCER_CASES.SET_DAY_OF_MEETING: {
      return {
        ...state,
        meetingDay: action?.payload
      }
    }
    // case for set calculator results
    case APP_DATA_REDUCER_CASES.SET_CALCULATOR_RESULTS: {
      return {
        ...state,
        calculatorResults: action?.payload,
        userEmail: action?.payload?.email
      }
    }
    // case for set user email
    case APP_DATA_REDUCER_CASES.SET_USER_EMAIL: {
      return {
        ...state,
        userEmail: action?.payload
      }
    }
    // case for showing success modal after booking
    case APP_DATA_REDUCER_CASES.SET_RESULT_MODAL_VISIBLE: {
      return {
        ...state,
        isShowResultModalVisible: action?.payload
      }
    }
    // case for showing success modal after booking
    case APP_DATA_REDUCER_CASES.SET_IS_EMAIL_VISIBLE: {
      return {
        ...state,
        isEmailScreenVisible: action?.payload
      }
    }
    case APP_DATA_REDUCER_CASES.SET_IS_SUCCESS_SCREEN_VISIBLE: {
      return {
        ...state,
        isSuccessScreenVisible: action?.payload
      }
    }
    case APP_DATA_REDUCER_CASES.SET_IS_DRAFT_CUSTOMER_CREATED: {
      return {
        ...state,
        isDraftCustomerCreated: action?.payload
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export default appDataReducer
