import { useTranslations } from '@qonsoll/translation'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { SCREEN_NAMES } from 'domains/ScreenSlider/__constants__'
import { useScreenSliderContext } from 'domains/ScreenSlider/contexts/ScreenSlider/useScreenSlider'
import { useEffect } from 'react'
import { Text } from '~/components'
import party from 'party-js'

const SuccessScreen = () => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { isSuccessScreenVisible } = useAppDataContext()
  const { currentScreen } = useScreenSliderContext()

  // [USE_EFFECTS]
  useEffect(() => {
    if (currentScreen.name === SCREEN_NAMES.SUCCESS) {
      const element = document.getElementById('trigger')

      if (element) {
        party.confetti(element, {
          count: party.variation.range(100, 150),
          size: party.variation.range(0.6, 1.5),
          speed: party.variation.range(500, 600),
          spread: party.variation.range(400, 500)
        })
      }
    }
  }, [isSuccessScreenVisible, currentScreen])

  return (
    <div className="row justify-content-center px-3">
      <div
        className="col-12 col-md-6 col-lg-5  d-lg-flex flex-lg-column align-items-lg-center"
        id="trigger">
        <div className="row justify-content-center mb-3">
          <div className="col-6 justify-content-center">
            <img
              src="/icons/success.png"
              alt="test"
              style={{ width: '100%' }}
            />
          </div>
        </div>

        <div className="row justify-content-center mb-3">
          <div className="col-10 justify-content-center">
            <Text secondary center>
              {t('Super! We look forward to hear more about your project! 🚀')}
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

SuccessScreen.propTypes = {}

export default SuccessScreen
