const COLLECTIONS = {
  USERS: 'users',
  TIMESLOTS: 'timeslots',
  USERS_TIMESLOTS_COLLECTION: 'usersTimeslots',
  CALCULATOR_RESULTS: 'calculatorResults',
  DESIGNS: 'designs',
  CUSTOMERS: 'customers',
  DRAFT_CUSTOMERS: 'draftCustomers',
  CUSTOMER_PROFILES: 'customerProfiles',
  COLORS: 'colors',
  TIMESLOTS_COLLECTION: 'timeslots',
  DRAFT_BOOKING: 'draftBooking'
}

export default COLLECTIONS
