import { DEFAULT_LANGUAGE, LANGUAGES } from '~/contexts/Translation/constants'

const checkLanguageAvailability = (language) => {
  if (typeof language !== 'string') {
    return DEFAULT_LANGUAGE
  }
  const isValidLanguage =
    LANGUAGES.findIndex(({ shortCode }) => shortCode === language) !== -1
  return isValidLanguage ? language : DEFAULT_LANGUAGE
}

export default checkLanguageAvailability
