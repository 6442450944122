import PropTypes from 'prop-types'
import {
  NavigationDotStyled,
  NavigationDotsStyled
} from './NavigationDots.styled'

const NavigationDots = (props) => {
  const { currentSlideIndex, onChange, slidesCount } = props

  //[COMPUTED_PROPERTIES]
  const screenSlidesArray = [...Array(slidesCount)]

  return (
    <NavigationDotsStyled id="navigation-dots">
      {screenSlidesArray.map((_, index) => (
        <NavigationDotStyled
          key={`navigationDot-${index}`}
          onClick={() => onChange?.(index)}
          active={index === currentSlideIndex}
        />
      ))}
    </NavigationDotsStyled>
  )
}

NavigationDots.propTypes = {
  currentSlideIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  slidesCount: PropTypes.number.isRequired
}
export default NavigationDots
