import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './Modal.module.css'

const Modal = ({ children, visible, onClose }) => {
  const [isVisible, setIsVisible] = useState()

  // Initial state
  useEffect(() => {
    setIsVisible(visible)
  }, [visible])

  return isVisible ? (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <span className={styles.close} onClick={onClose}>
          &times;
        </span>
        {children}
      </div>
    </div>
  ) : null
}

Modal.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  onClose: PropTypes.func
}

export default Modal
