import { useTranslations } from '@qonsoll/translation'
import { WarningOverflowWrapper } from 'domains/App/components/WarningOverflow/WarningOverflow.styles'
import PropTypes from 'prop-types'
import { Button, Title } from '~/components'

const WarningOverflow = (props) => {
  const { onButtonClick } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  return (
    <WarningOverflowWrapper>
      <div className="row align-items-center justify-content-center g-0">
        <div className="col-12 col-md-8">
          <Title as="h5" className="mb-4 " center>
            {t(
              'Firstly schedule meeting, and then fill up additional information'
            )}
          </Title>
        </div>
        <div className="col-8 d-none d-md-flex justify-content-center">
          <Button onClick={onButtonClick} className="m-auto" variant="primary">
            {t('Go to booking screen')}
          </Button>
        </div>
      </div>
    </WarningOverflowWrapper>
  )
}

WarningOverflow.propTypes = {
  onButtonClick: PropTypes.func
}

export default WarningOverflow
