import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { SCREEN_NAMES } from 'domains/ScreenSlider/__constants__'
import { useCustomerData } from '~/contexts/CustomerData'
import { useHandleWholeBookingProcess } from '~/hooks'
import useColorSave from '../../../Color/hooks/post/useColorSave'
import useDescriptionSave from '../../../Idea/hooks/post/useDescriptionSave'
import useLogoSave from '../../../Logo/hooks/post/useLogoSave'
import useDesignSave from '../../../Style/hooks/post/useDesignSave'

const useScreenSliderNextButtonFunction = () => {
  // [ADDITIONAL_HOOKS]
  const { userEmail, isEmailScreenVisible } = useAppDataContext()
  const [{ customerProfileData, draftCustomerData }] = useCustomerData()

  // FUNCTIONS
  const [handleWholeBookingProcess, bookingLoading] =
    useHandleWholeBookingProcess()
  const [handleUpdateCustomerColor, colorUpdateLoading] = useColorSave()
  const [handleUpdateCustomerDesign, designUpdateLoading] = useDesignSave()
  const [handleUpdateCustomerDescription, descriptionUpdateLoading] =
    useDescriptionSave()
  const [handleUpdateCustomerLogo, logoUpdateLoading] = useLogoSave()

  const nextButtonFunction = {
    [SCREEN_NAMES.BOOKING]:
      userEmail && !isEmailScreenVisible && handleWholeBookingProcess,
    [SCREEN_NAMES.EMAIL]: handleWholeBookingProcess,
    [SCREEN_NAMES.LOGO]:
      !customerProfileData?.logoUrl &&
      !draftCustomerData?.logoUrl &&
      handleUpdateCustomerLogo,
    [SCREEN_NAMES.COLOR]:
      !customerProfileData?.colorIds?.length &&
      !draftCustomerData?.color &&
      handleUpdateCustomerColor,
    [SCREEN_NAMES.STYLE]:
      !customerProfileData?.designIds?.length &&
      !draftCustomerData?.designId &&
      handleUpdateCustomerDesign,
    [SCREEN_NAMES.IDEA]:
      !customerProfileData?.description &&
      !draftCustomerData?.idea &&
      handleUpdateCustomerDescription
  }
  const nextButtonLoadings = {
    [SCREEN_NAMES.BOOKING]: userEmail && bookingLoading,
    [SCREEN_NAMES.EMAIL]: bookingLoading,
    [SCREEN_NAMES.LOGO]: logoUpdateLoading,
    [SCREEN_NAMES.COLOR]: colorUpdateLoading,
    [SCREEN_NAMES.STYLE]: designUpdateLoading,
    [SCREEN_NAMES.IDEA]: descriptionUpdateLoading
  }

  return { nextButtonFunction, nextButtonLoadings }
}

export default useScreenSliderNextButtonFunction
