import moment, { ISO_8601 } from 'moment'

const generateMonth = (t, avaibility) => {
  const allDays = []
  let todayMinusFiveDays = moment()
    .subtract(5, 'days')
    .toISOString()
    .replace(/T.*/gi, 'T00:00:00.000Z')
  const todayPlusOneMonthAndOneDay = moment()
    .add(1, 'month')
    .toISOString()
    .replace(/T.*/gi, 'T00:00:00.000Z')

  while (
    moment(todayMinusFiveDays, ISO_8601).isSameOrBefore(
      moment(todayPlusOneMonthAndOneDay, ISO_8601)
    )
  ) {
    const todayMinusFiveDaysMomentObj = moment(
      todayMinusFiveDays,
      ISO_8601
    ).utc()
    const isToday =
      moment().format('YYYY-MM-DD') ===
      todayMinusFiveDaysMomentObj.format('YYYY-MM-DD')

    allDays.push({
      month: todayMinusFiveDaysMomentObj.format('MMM'),
      date: todayMinusFiveDaysMomentObj.date(),
      day: isToday ? t('Today') : todayMinusFiveDaysMomentObj.format('ddd'),
      isDisable:
        todayMinusFiveDaysMomentObj.isBefore(
          moment().utc().startOf('day'),
          'day'
        ) ||
        // Check if day is Saturday(6) of Sunday(7),so we need to disable item
        [6, 7].includes(moment(todayMinusFiveDays, ISO_8601).isoWeekday()) ||
        (avaibility[todayMinusFiveDays] !== undefined &&
          !avaibility[todayMinusFiveDays]),
      ISO_8601: todayMinusFiveDays
    })

    todayMinusFiveDays = todayMinusFiveDaysMomentObj.add(1, 'day').toISOString()
  }

  return allDays
}

export default generateMonth
