import { useTranslations } from '@qonsoll/translation'
import { setDoc, doc, collection } from 'firebase/firestore'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import {
  CALCULATOR_RESULT_SEARCH_PARAM,
  COMMISSION_PARTNER_NAME_SEARCH_PARAM,
  ADVERTISEMENT_CAMPAIGN_ID_SEARCH_PARAM,
  COLLECTIONS
} from '~/__constants__'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import useQuery from '~/hooks/useQuery'
import { firestore } from '~/services/fb'

const useCreateCustomer = () => {
  // [ADDITIONAL_HOOKS]
  const { userEmail } = useAppDataContext()
  const calculatorResultId = useQuery().get(CALCULATOR_RESULT_SEARCH_PARAM)
  const commissionPartnerName = useQuery().get(
    COMMISSION_PARTNER_NAME_SEARCH_PARAM
  )
  const advertisementCampaignId = useQuery().get(
    ADVERTISEMENT_CAMPAIGN_ID_SEARCH_PARAM
  )
  const setSearchParams = useSearchParams()[1]

  const { t } = useTranslations()
  return async (providedEmail) => {
    try {
      const email = providedEmail || userEmail

      const customerId = doc(collection(firestore, COLLECTIONS.CUSTOMERS)).id
      const customerProfileId = doc(
        collection(firestore, COLLECTIONS.CUSTOMER_PROFILES)
      ).id

      const data = {
        _createdAtValueOf: moment().valueOf(),
        calculatorResultId,
        email,
        _id: customerId,
        customerProfileId,
        commissionPartnerName,
        advertisementCampaignId
      }

      await setDoc(
        doc(firestore, COLLECTIONS.DRAFT_CUSTOMERS, customerId),
        data
      )

      const queryParameters = { customerId: customerId }
      if (calculatorResultId) {
        queryParameters.calculatorResultId = calculatorResultId
      }
      if (commissionPartnerName) {
        queryParameters.ref = commissionPartnerName
      }
      if (advertisementCampaignId) {
        queryParameters.adv = advertisementCampaignId
      }
      setSearchParams(queryParameters)
    } catch (error) {
      console.error('Error in handleMakingRequestForCreatingCustomer', error)
      throw Error(t('Something went wrong while customer creation.'))
    }
  }
}

export default useCreateCustomer
