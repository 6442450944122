import { useTranslations } from '@qonsoll/translation'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { SCREEN_NAMES } from 'domains/ScreenSlider/__constants__'
import { useMediaQuery } from '~/hooks'

const MOBILE_HEADERS_CONFIG = {
  [SCREEN_NAMES.BOOKING]: 'Schedule meeting.',
  [SCREEN_NAMES.LOGO]: 'Upload your brand logotype.',
  [SCREEN_NAMES.COLOR]: 'Select your main color.',
  [SCREEN_NAMES.STYLE]: 'Select style.',
  [SCREEN_NAMES.IDEA]: 'Describe your idea.',
  [SCREEN_NAMES.EMAIL]: 'E-mail.',
  [SCREEN_NAMES.SUCCESS]: 'Perfect!'
}

const HEADER_CONFIG = {
  [SCREEN_NAMES.IDEA]: 'Prepare meeting.'
}

const useScreenSliderTitle = (currentScreenName) => {
  // [ADDITIONAL_HOOKS]
  const { isSuccessScreenVisible } = useAppDataContext()
  const isMobile = useMediaQuery('(max-width: 992px)')
  const { t } = useTranslations()

  let screenSliderTitle =
    HEADER_CONFIG?.[currentScreenName] && !isMobile
      ? t(HEADER_CONFIG[currentScreenName])
      : isMobile
      ? t(MOBILE_HEADERS_CONFIG[currentScreenName])
      : t('Schedule meeting.')

  if (isSuccessScreenVisible && currentScreenName === SCREEN_NAMES.SUCCESS) {
    screenSliderTitle = t(MOBILE_HEADERS_CONFIG[currentScreenName])
  }
  // get the last character and make it accent color
  return (
    screenSliderTitle &&
    `${screenSliderTitle.slice(
      0,
      -1
    )}<span style='color: var(--booking-module-typography-color-accent)'>${
      screenSliderTitle.slice(-1)[0]
    }</span>`
  )
}

export default useScreenSliderTitle
