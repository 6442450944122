const APP_DATA_REDUCER_CASES = {
  SET_MEETING_TIME: 'SET_MEETING_TIME',
  SET_LOGO: 'SET_LOGO',
  SET_COLORS: 'SET_COLORS',
  SET_IDEA: 'SET_IDEA',
  SET_STYLE: 'SET_STYLE',
  SET_DAY_PART: 'SET_DAY_PART',
  SET_DAY_OF_MEETING: 'SET_DAY_OF_MEETING',
  SET_CALCULATOR_RESULTS: 'SET_CALCULATOR_RESULTS',
  SET_USER_EMAIL: 'SET_USER_EMAIL',
  SET_RESULT_MODAL_VISIBLE: 'SET_RESULT_MODAL_VISIBLE',
  SET_FROM_CUSTOMER_PROFILE: 'SET_FROM_CUSTOMER_PROFILE',
  SET_IS_EMAIL_VISIBLE: 'SET_IS_EMAIL_VISIBLE',
  SET_IS_SUCCESS_SCREEN_VISIBLE: 'SET_IS_SUCCESS_SCREEN_VISIBLE',
  SET_IS_DRAFT_CUSTOMER_CREATED: 'SET_IS_DRAFT_CUSTOMER_CREATED'
}
export default APP_DATA_REDUCER_CASES
