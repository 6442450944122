import { useListenDays } from 'domains/TimeSlot/hooks'
import { useMemo } from 'react'

const useTransformDays = () => {
  // [ADDITIONAL HOOKS]
  const [days, loading] = useListenDays()

  // [HELPER FUNCTIONS]
  const allTimeslots = useMemo(() => {
    if (loading) {
      return {}
    }

    return Object.fromEntries(
      days.map(({ _id, isTimeslotsAvailiable }) => [_id, isTimeslotsAvailiable])
    )
  }, [days, loading])

  return allTimeslots
}

export default useTransformDays
