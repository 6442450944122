import styled from 'styled-components'

const Wrapper = styled.div`
  --bs-columns: 9;
  overflow-y: auto;
  @media screen and (min-width: 768px) {
    --bs-columns: 12;
  }
  @media screen and (min-width: 992px) {
    --bs-columns: 16;
  }
  @media screen and (min-width: 1400px) {
    --bs-columns: 10;
  }
`

export default Wrapper
