import PropTypes from 'prop-types'
import cn from 'classnames'
import { createElement } from 'react'
import styles from './Title.module.css'
import typographyStyles from '../../Typography/Typography.module.css'

const DEFAULT_TAG = 'h1'

/**
 * It returns a React element
 * @param props - This is the props object that is passed to the component,
 * has `as` - tag's name.
 * @returns A React component that renders an HTML element with the tag name specified by the `as`
 * prop, or `h1` if no `as` prop is provided. The component's class name is set to the tag name.
 */
const Title = (props) => {
  const { as, center, secondary, children, className, ...rest } = props

  // [COMPUTED PROPPERTIES]
  const tag = as || DEFAULT_TAG

  return createElement(tag, {
    className: cn({
      [styles[tag]]: true,
      [typographyStyles['text-center']]: center,
      [typographyStyles['text-secondary']]: secondary,
      [className]: className
    }),
    dangerouslySetInnerHTML: { __html: children },
    ...rest
  })
}

Title.propTypes = {
  children: PropTypes.string,
  as: PropTypes.string,
  className: PropTypes.string,
  center: PropTypes.bool
}

export default Title
