import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled'
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled'
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled'
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import classNames from 'classnames'
import RCNotification from 'rc-notification'

import { isObject } from '~/helpers'

let messageInstance
let defaultDuration = 3
let defaultTop
let key = 1
let transitionName = 'move-up'
let maxCount
const prefixCls = 'booking-message'
const rootPrefixCls = 'booking-message-content'
const iconPrefixCls = 'booking-message-icon'

export function getKeyThenIncreaseKey() {
  return key++
}

function getRCNotificationInstance(args, callback) {
  if (messageInstance) {
    callback({
      prefixCls,
      rootPrefixCls,
      iconPrefixCls,
      instance: messageInstance
    })
    return
  }

  const instanceConfig = {
    prefixCls,
    transitionName: `${rootPrefixCls}-${transitionName}`,
    style: { top: defaultTop },
    maxCount
  }

  RCNotification.newInstance(instanceConfig, (instance) => {
    if (messageInstance) {
      callback({
        prefixCls,
        rootPrefixCls,
        iconPrefixCls,
        instance: messageInstance
      })
      return
    }
    messageInstance = instance

    callback({ prefixCls, rootPrefixCls, iconPrefixCls, instance })
  })
}

const typeToIcon = {
  info: InfoCircleFilled,
  success: CheckCircleFilled,
  error: CloseCircleFilled,
  warning: ExclamationCircleFilled,
  loading: LoadingOutlined
}

export const typeList = Object.keys(typeToIcon)

function getRCNoticeProps(args, prefixCls) {
  const duration = args.duration !== undefined ? args.duration : defaultDuration
  const IconComponent = typeToIcon[args.type]
  const messageClass = classNames(`${prefixCls}-custom-content`, {
    [`${prefixCls}-${args.type}`]: args.type
  })
  return {
    key: args.key,
    duration,
    style: args.style || {},
    className: args.className,
    content: (
      <div className={messageClass}>
        {args.icon || (IconComponent && <IconComponent />)}
        <span>{args.content}</span>
      </div>
    ),
    onClose: args.onClose
  }
}

function notice(args) {
  const target = args.key || getKeyThenIncreaseKey()

  getRCNotificationInstance(args, ({ prefixCls, iconPrefixCls, instance }) => {
    instance.notice(
      getRCNoticeProps({ ...args, key: target }, prefixCls, iconPrefixCls)
    )
  })
}

const api = {
  open: notice,
  destroy(messageKey) {
    if (messageInstance) {
      if (messageKey) {
        messageInstance?.removeNotice(messageKey)
      } else {
        messageInstance?.destroy()
        messageInstance = null
      }
    }
  }
}

export function attachTypeApi(originalApi, type) {
  originalApi[type] = (content, duration, onClose) => {
    if (isObject(content)) {
      return originalApi.open({ ...content, type })
    }

    if (typeof duration === 'function') {
      onClose = duration
      duration = undefined
    }

    return originalApi.open({ content, duration, type, onClose })
  }
}

typeList.forEach((type) => attachTypeApi(api, type))

export default api
