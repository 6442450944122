import { useMemo } from 'react'
import { IS_ADDITIONAL_STEPS_SHOW } from '~/__constants__'
import { useQuery } from '~/hooks/index'

const useIsAdditionalStepsShow = () => {
  const isAdditionalStepsShow = useQuery().get(IS_ADDITIONAL_STEPS_SHOW)

  return useMemo(() => {
    const convertedValue = JSON.parse(isAdditionalStepsShow)
    if (convertedValue === null) {
      return true
    }
    return convertedValue
  }, [isAdditionalStepsShow])
}

export default useIsAdditionalStepsShow
