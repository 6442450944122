import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { useMemo } from 'react'
import { useCustomerData } from '~/contexts/CustomerData'
import useIsAdditionalStepsShow from '~/hooks/useIsAdditionalStepsShow'
import {
  EmailScreen,
  IdeaScreen,
  ScheduleMeetingScreen,
  SuccessScreen
} from '~/screens'
import { SCREEN_NAMES } from 'domains/ScreenSlider/__constants__'
import { useMediaQuery } from '~/hooks'

const useGetScreens = (currentSlideIndex) => {
  // [ADDITIONAL_HOOKS]
  const { userEmail, isEmailScreenVisible, isSuccessScreenVisible } =
    useAppDataContext() || {}
  const [{ customerData }] = useCustomerData()
  const isAdditionalStepsShow = useIsAdditionalStepsShow()
  const isMobile = useMediaQuery('(max-width: 992px)')

  // [COMPUTED_PROPERTIES]
  const screens = useMemo(() => {
    const map = [
      {
        component: <ScheduleMeetingScreen />,
        name: SCREEN_NAMES.BOOKING,
        title: isMobile
      },
      { component: <EmailScreen />, name: SCREEN_NAMES.EMAIL },
      // { component: <LogoScreen />, name: SCREEN_NAMES.LOGO },
      // { component: <ColorScreen />, name: SCREEN_NAMES.COLOR },
      // { component: <StyleScreen />, name: SCREEN_NAMES.STYLE },
      { component: <IdeaScreen />, name: SCREEN_NAMES.IDEA },
      { component: <SuccessScreen />, name: SCREEN_NAMES.SUCCESS }
    ]

    let filteredScreens = map.filter(({ name }) =>
      isSuccessScreenVisible ? true : name !== SCREEN_NAMES.SUCCESS
    )
    if (!isAdditionalStepsShow) {
      filteredScreens = filteredScreens.filter(({ name }) =>
        !userEmail
          ? [SCREEN_NAMES.BOOKING, SCREEN_NAMES.EMAIL].includes(name)
          : name === SCREEN_NAMES.BOOKING
      )
    } else if (!isEmailScreenVisible) {
      filteredScreens = filteredScreens.filter(({ name }) =>
        userEmail || customerData?.email ? name !== SCREEN_NAMES.EMAIL : true
      )
    }

    return filteredScreens.map((item, index) => ({ ...item, index }))
  }, [
    userEmail,
    customerData,
    isAdditionalStepsShow,
    isEmailScreenVisible,
    isSuccessScreenVisible
  ])

  const currentScreen = useMemo(
    () => screens.find(({ index }) => index === currentSlideIndex),
    [screens, currentSlideIndex]
  )

  return { screens, currentScreen }
}

export default useGetScreens
