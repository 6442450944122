import 'rc-segmented/assets/index.css'

import Segmented from 'rc-segmented'
import styled from 'styled-components'

const Segment = styled(Segmented)`
  &.rc-segmented {
    overflow: hidden;
    padding: var(--booking-module-segment-padding);
    background-color: var(--booking-module-segment-bg-color);
    border-radius: var(--booking-module-segment-border-radius);
    /*This z-index need to fix box shadow in safari */
    z-index: 0;
    & > .rc-segmented-group {
      border-radius: var(--booking-module-segment-border-radius);
      & > .rc-segmented-item {
        color: var(--booking-module-segment-item-color);

        gap: var(--booking-module-segment-item-gap);
        padding: var(--booking-module-segment-item-padding);
        border-radius: var(--booking-module-segment-border-radius);

        & > .rc-segmented-item-label {
          font-size: var(--booking-module-segment-item-fs);
          font-family: var(--booking-module-segment-item-font-family);
          line-height: var(--booking-module-segment-item-lh);
          &::after {
            display: block;
            content: attr(title);
            font-weight: bold;
            height: 1px;
            color: transparent;
            overflow: hidden;
            visibility: hidden;
          }
        }

        &.rc-segmented-item-selected {
          box-shadow: var(--booking-module-segment-box-shadow);
          font-weight: var(--booking-module-segment-item-fw-selected);
          color: var(--booking-module-segment-item-selected-color);
        }
      }
      & > .rc-segmented-thumb {
        border-radius: var(--booking-module-segment-border-radius);
        box-shadow: var(--booking-module-segment-box-shadow);
      }
    }
  }
`

export default Segment
