import {
  AppDataContext,
  AppDataDispatchContext
} from 'app/domains/App/contexts/AppData/AppDataContext.js'

import { useContext } from 'react'

const useAppDataDispatch = () => {
  const context = useContext(AppDataDispatchContext)

  if (context === undefined) {
    throw new Error(
      'useAppDataDispatch must be used within a AppDataContext.Provider'
    )
  }

  return context
}

const useAppDataContext = () => {
  const context = useContext(AppDataContext)

  if (context === undefined) {
    throw new Error(
      'useAppDataContext must be used within a AppDataContext.Provider'
    )
  }

  return context
}

export { useAppDataDispatch, useAppDataContext }
