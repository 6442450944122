import { useCallback } from 'react'
import PropTypes from 'prop-types'
import Provider from '@qonsoll/translation'
import { child, get, ref as dbRef, set } from 'firebase/database'
import { database } from '~/services/fb'
import { CURRENT_APP, DEFAULT_LANGUAGE, LANGUAGES } from './constants'

const TranslationsProvider = (props) => {
  const { children } = props

  // [HELPER_FUNCTIONS]
  const onWrite = useCallback(
    ({ ref, value }) => set(dbRef(database, ref), value),
    []
  )
  const onRead = useCallback(({ ref }) => get(child(dbRef(database), ref)), [])

  return (
    <Provider
      languages={LANGUAGES}
      defaultLanguage={DEFAULT_LANGUAGE}
      currentApp={CURRENT_APP}
      onWrite={onWrite}
      onRead={onRead}>
      {children}
    </Provider>
  )
}

TranslationsProvider.propTypes = {
  children: PropTypes.any
}

export default TranslationsProvider
