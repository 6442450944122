import { useTranslations } from '@qonsoll/translation'
import { generateMonth } from 'domains/Day/helpers'
import { useGetExtraSlidesOnMedia } from 'domains/Day/hooks'
import { useEffect, useMemo, useRef } from 'react'
import {
  DaySelectWrapper,
  GradientLeft,
  GradientRight
} from './DaySelect.styled'

import { DaySimpleView } from 'app/domains/Day/components'
import Slider from 'react-slick'
import sliderSettings from 'app/domains/Day/__constants__'
import { useUpdateAppData } from 'domains/App/hooks/post'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'

let firstClientX, clientX

const preventTouch = (e) => {
  const minValue = 5 // threshold

  clientX = e.touches[0].clientX - firstClientX

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    e.preventDefault()
    e.returnValue = false

    return false
  }
}

const touchStart = (e) => {
  firstClientX = e.touches[0].clientX
}

const DaySelect = () => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { handleChooseDayOfMeeting } = useUpdateAppData()
  const { meetingDay, days } = useAppDataContext()
  const currentFakeItemLength = useGetExtraSlidesOnMedia()
  const isInitialize = useRef(false)
  // [COMPONENT_STATE_HOOKS]
  const sliderRef = useRef(null)

  // [COMPUTED_PROPERTIES]
  const dayData = useMemo(() => generateMonth(t, days), [t, days])

  let containerRef = useRef()

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('touchstart', touchStart)
      containerRef.current.addEventListener('touchmove', preventTouch, {
        passive: false
      })
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('touchstart', touchStart)
        containerRef.current.removeEventListener('touchmove', preventTouch, {
          passive: false
        })
      }
    }
  }, [])
  useEffect(() => {
    if (!isInitialize.current && Object.keys(days)?.length) {
      const initialSlide = dayData.findIndex(({ isDisable }) => !isDisable)
      handleChooseDayOfMeeting(dayData[initialSlide].ISO_8601)
      sliderRef?.current?.slickGoTo(initialSlide)
      isInitialize.current = true
    }
  }, [days])

  return (
    <DaySelectWrapper ref={containerRef}>
      <Slider {...sliderSettings} ref={sliderRef}>
        {dayData.map((item, index) => (
          <DaySimpleView
            key={item.date}
            isSelected={item.ISO_8601 === meetingDay}
            onClick={() => {
              if (!item?.isDisable) {
                handleChooseDayOfMeeting(item.ISO_8601)
                sliderRef?.current?.slickGoTo(index)
              }
            }}
            {...item}
          />
        ))}
        {Array.from(Array(currentFakeItemLength)).map(() => (
          <div />
        ))}
      </Slider>
      <GradientLeft />
      <GradientRight />
    </DaySelectWrapper>
  )
}
export default DaySelect
