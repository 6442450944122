import moment from 'moment'
import { useCallback } from 'react'
import { STATISTIC_COLLECTIONS } from '~/__constants__'

import { ref, runTransaction } from 'firebase/database'
import { database } from '~/services/fb'

/**
 * This function using to increase counter in RTDB.
 *
 * @param {string} path
 * @returns {Promise<TransactionResult>}
 */
const increaseCounter = ({ path }) => {
  const dbRef = ref(database, path)

  return runTransaction(dbRef, (counter) => (counter ? ++counter : 1))
}

const useIncreaseStatisticCounter = () =>
  useCallback(({ field, functionName }) => {
    const today = moment().utc().startOf('day').valueOf()

    increaseCounter({
      path: `${STATISTIC_COLLECTIONS.STATISTIC_PATH}/${today}/${field}`
    })?.catch((error) =>
      console.error(
        `error in ${functionName} while ${field} incrementing transaction:`,
        error
      )
    )
  }, [])

export default useIncreaseStatisticCounter
