import { ref, uploadString, getDownloadURL } from 'firebase/storage'
import { storage } from '~/services/fb'
import { FORMAT_STRING, STORAGE_FOLDERS } from '~/__constants__'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid'

const useUploadApplicationLogo = () => {
  // [ADDITIONAL_HOOKS]
  const { logo } = useAppDataContext()

  return async () => {
    if (!logo) {
      throw new Error('Skip')
    }
    const seed = uuidv4()
    const storageFileId = uuidv5(STORAGE_FOLDERS.APPLICATION_LOGOS, seed)
    const filePath = `${STORAGE_FOLDERS.APPLICATION_LOGOS}/${storageFileId}`

    const storageRef = ref(storage, filePath)

    await uploadString(storageRef, logo, FORMAT_STRING)

    return getDownloadURL(storageRef)
  }
}

export default useUploadApplicationLogo
