import { useCallback } from 'react'
import { doc, updateDoc } from 'firebase/firestore'
import { firestore } from '~/services/fb'
import { useQuery } from '~/hooks'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { CALCULATOR_RESULT_SEARCH_PARAM, COLLECTIONS } from '~/__constants__'

const useCalculatorResultEmailUpdate = () => {
  // [ADDITIONAL_HOOKS]
  const { userEmail } = useAppDataContext()
  const calculatorResultId = useQuery().get(CALCULATOR_RESULT_SEARCH_PARAM)

  return useCallback(
    (providedEmail) => {
      if (calculatorResultId) {
        const calculatorResultRef = doc(
          firestore,
          COLLECTIONS.CALCULATOR_RESULTS,
          calculatorResultId
        )

        return updateDoc(calculatorResultRef, {
          email: providedEmail || userEmail
        })
      }
    },
    [calculatorResultId, userEmail]
  )
}

export default useCalculatorResultEmailUpdate
