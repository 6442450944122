import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { getTimeslotDayPart } from '~/domains/TimeSlot/helpers'
import isAppointmentBooked from '~/helpers/isAppointmentBooked'

const useIsAppointmentBooked = () => {
  // [ADDITIONAL_HOOKS]
  const { forWhomMeetingIsBooked, meetingTime, meetingDay } =
    useAppDataContext()

  return () =>
    isAppointmentBooked({
      forWhomMeetingIsBooked,
      meetingTime,
      dayPart: getTimeslotDayPart(meetingTime, true),
      meetingDay
    })
}

export default useIsAppointmentBooked
