import PropTypes from 'prop-types'
import { TimeSlotSelectStyled } from './TimeSlotSelect.styled'
import { TimeSlotSelectItem } from 'domains/TimeSlot/components'

const TimeSlotSelect = (props) => {
  const { timeslots, dayPart, onTimeSlotSelect } = props

  return (
    <TimeSlotSelectStyled className="grid gy-0" style={{ '--bs-gap': 0 }}>
      {timeslots?.map((item, index) => (
        <div className="g-col-6 my-2 px-2" key={`${index}-time`}>
          <TimeSlotSelectItem
            range={item}
            dayPart={dayPart}
            onSelect={onTimeSlotSelect}
          />
        </div>
      ))}
    </TimeSlotSelectStyled>
  )
}

TimeSlotSelect.propTypes = {
  timeslots: PropTypes.array,
  dayPart: PropTypes.string,
  onTimeSlotSelect: PropTypes.func
}

export default TimeSlotSelect
