import './styles/index.css'
import 'moment/locale/nb'
import { TranslationProvider } from '~/contexts/Translation'
import App from './app'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import checkLanguageAvailability from './app/helpers/checkLanguageAvailability'

const root = createRoot(document.getElementById('root'))

const previousLanguage = checkLanguageAvailability(
  localStorage.getItem('language')
)
const redirectPath = `/${previousLanguage}${window.location.search || ''}`

root.render(
  <React.StrictMode>
    <TranslationProvider>
      <BrowserRouter>
        <Routes>
          <Route path=":languageID" element={<App />} />
          <Route path="/" element={<Navigate to={redirectPath} />} />
        </Routes>
      </BrowserRouter>
    </TranslationProvider>
  </React.StrictMode>
)
