import { useQuery } from '~/hooks'
import { CustomerDataContext } from './CustomerDataContext'
import { collection, doc } from 'firebase/firestore'

import PropTypes from 'prop-types'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { COLLECTIONS, CUSTOMER_ID_SEARCH_PARAM } from '~/__constants__'
import { firestore } from '~/services/fb'

const CustomerDataProvider = (props) => {
  const { children } = props
  // [ADDITIONAL_HOOKS]
  const customerId = useQuery().get(CUSTOMER_ID_SEARCH_PARAM)

  // [DATA_FETCH]
  const [customerData, customerLoading] = useDocumentData(
    customerId && doc(collection(firestore, COLLECTIONS.CUSTOMERS), customerId)
  )
  const [draftCustomerData] = useDocumentData(
    customerId &&
      doc(collection(firestore, COLLECTIONS.DRAFT_CUSTOMERS), customerId)
  )
  const [customerProfileData, customerProfileLoading] = useDocumentData(
    customerData?.profileId &&
      doc(
        collection(firestore, COLLECTIONS.CUSTOMER_PROFILES),
        customerData?.profileId
      )
  )
  const [colorData, colorLoading] = useDocumentData(
    customerProfileData?.colorIds?.length &&
      doc(
        collection(firestore, COLLECTIONS.COLORS),
        customerProfileData?.colorIds[0]
      )
  )

  return (
    <CustomerDataContext.Provider
      value={[
        {
          customerData: { ...customerData, ...draftCustomerData },
          draftCustomerData,
          customerProfileData,
          colorData
        },
        customerLoading || customerProfileLoading || colorLoading
      ]}>
      {children}
    </CustomerDataContext.Provider>
  )
}

CustomerDataProvider.propTypes = {
  children: PropTypes.node
}
export default CustomerDataProvider
