import { useTranslations } from '@qonsoll/translation'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { doc, updateDoc } from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { COLLECTIONS, CUSTOMER_ID_SEARCH_PARAM } from '~/__constants__'
import { Notification } from '~/components'
import useQuery from '~/hooks/useQuery'
import { firestore } from '~/services/fb'
import useUploadApplicationLogo from '../../useUploadApplicationLogo'

const useLogoSave = () => {
  // [ADDITIONAL_HOOKS]
  const { logoFormat } = useAppDataContext()
  const customerId = useQuery().get(CUSTOMER_ID_SEARCH_PARAM)
  const uploadLogoToStorage = useUploadApplicationLogo()
  const { t } = useTranslations()

  // [COMPONENT_STATE_HOOKS]
  const [loading, setLoading] = useState(false)

  // [HELPER_FUNCTIONS]
  const handleUpdateCustomerLogo = useCallback(async () => {
    try {
      setLoading(true)
      const logoUrl = await uploadLogoToStorage()

      await updateDoc(doc(firestore, COLLECTIONS.DRAFT_CUSTOMERS, customerId), {
        logoFormat,
        logoUrl
      })

      Notification.success(t('Logo successfully saved'))
    } catch (error) {
      console.error('Error in useLogoSave', error)
      if (error?.message === 'Skip') {
        return
      }
      const errorMessage =
        error?.message === 'No logo provided'
          ? 'Upload some file that will be using for logo'
          : 'Error occurred while logo saving'
      Notification.error(t(errorMessage))
      throw error
    } finally {
      setLoading(false)
    }
  }, [logoFormat, customerId])

  return [handleUpdateCustomerLogo, loading]
}

export default useLogoSave
