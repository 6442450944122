import {
  AppDataContext,
  AppDataDispatchContext
} from 'domains/App/contexts/AppData/AppDataContext'
import { useReducer } from 'react'

import PropTypes from 'prop-types'
import appDataReducer from 'domains/App/contexts/AppData/appDataReducer.js'
import { checkPhaseOfDay } from '~/helpers'
import useSyncCustomerAndAppData from 'domains/App/hooks/listen/useSyncCustomerAndAppData'
import {
  useTransformDays,
  useTransformTimeslots
} from '~/domains/TimeSlot/hooks'

// initial app data object with empty values
const initialAppData = {
  meetingTime: null,
  logo: null,
  logoFormat: null,
  color: null,
  idea: null,
  styleId: null,
  dayPart: checkPhaseOfDay(),
  meetingDay: null,
  forWhomMeetingIsBooked: null,
  calculatorResults: null,
  userEmail: null,
  isShowResultModalVisible: false,
  isSuccessScreenVisible: false,
  isDraftCustomerCreated: false,
  isEmailScreenVisible: false // using only in case when schedule button clicked and we don't have initial email value.
}

const AppDataProvider = (props) => {
  const { children } = props

  // creating dispatch function
  const [appData, appDataDispatch] = useReducer(appDataReducer, initialAppData)
  useSyncCustomerAndAppData(appDataDispatch)
  const timeslots = useTransformTimeslots(appData)
  const days = useTransformDays()

  return (
    <AppDataContext.Provider value={{ ...appData, timeslots, days }}>
      <AppDataDispatchContext.Provider value={appDataDispatch}>
        {children}
      </AppDataDispatchContext.Provider>
    </AppDataContext.Provider>
  )
}
AppDataProvider.propTypes = {
  children: PropTypes.node
}
export default AppDataProvider
