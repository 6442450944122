import { useTranslations } from '@qonsoll/translation'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { Text, Input, Title } from '~/components'

const EmailScreen = () => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { userEmail } = useAppDataContext()

  return (
    <div className="row justify-content-center px-4">
      <div className="col-12 mb-4 mb-lg-5 d-lg-flex flex-lg-column align-items-lg-center p-0">
        <Title as="h5" className="d-none d-lg-block">
          {t('E-mail')}
        </Title>
        <Text secondary>
          {t('Enter your e-mail below and we will contact with you.')}
        </Text>
      </div>
      <div className="col-12 col-lg-8">
        <Input
          id="userEmailInput"
          type="email"
          placeholder={`${t('Enter e-mail here')}...`}
          defaultValue={userEmail}
        />
      </div>
    </div>
  )
}

export default EmailScreen
