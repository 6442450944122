import { useTranslations } from '@qonsoll/translation'
import { useScreenSliderContext } from 'domains/ScreenSlider/contexts/ScreenSlider/useScreenSlider'
import { Text } from '~/components'
import HeaderStyled, { HeaderDivider } from './Header.styled'
import { NavigationDots } from 'app/components'

const Header = () => {
  // [ADDITIONAL HOOKS]
  const {
    PrevScreenButtonIcon,
    screenSliderCurrentSlide,
    handlePrevScreenSlide,
    handleChangeSlide,
    numberScreenSlides
  } = useScreenSliderContext()
  const { t } = useTranslations()

  return (
    <HeaderStyled>
      <div className="px-3 container d-flex align-center">
        <div
          className="row justify-content-between align-items-center"
          style={{ flex: 1 }}>
          <div className="col-auto ">
            <img src="/icons/senseteqLogo.svg" alt="senseteq-logo" />
          </div>
          <div
            className="col-auto d-flex align-items-center"
            style={{ height: '100%' }}>
            {PrevScreenButtonIcon && (
              <>
                <PrevScreenButtonIcon
                  fill="var(--booking-module-neutral)"
                  width="24px"
                  className="d-lg-none"
                  style={{ cursor: 'pointer' }}
                  onClick={handlePrevScreenSlide}
                />
                <HeaderDivider className="d-lg-none" />
              </>
            )}

            {numberScreenSlides > 1 && (
              <div
                className="d-flex flex-column justify-content-between justify-content-lg-around align-items-end"
                style={{ height: '100%' }}>
                <NavigationDots
                  currentSlideIndex={screenSliderCurrentSlide}
                  onChange={handleChangeSlide}
                  slidesCount={numberScreenSlides}
                />
                <Text size="body2" className="d-lg-none">{`${t('Step')} ${
                  screenSliderCurrentSlide + 1
                }`}</Text>
              </div>
            )}
          </div>
        </div>
      </div>
    </HeaderStyled>
  )
}
export default Header
