import { firestore } from '~/services/fb'
import { COLLECTIONS } from '~/__constants__'
import { collection } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'

const useListenTimeslots = ({ meetingDay }) => {
  const [timeslots, loading, error] = useCollectionData(
    meetingDay &&
      collection(
        firestore,
        COLLECTIONS.TIMESLOTS,
        meetingDay,
        COLLECTIONS.USERS_TIMESLOTS_COLLECTION
      )
  )

  return [timeslots, loading, error]
}

export default useListenTimeslots
