import PropTypes from 'prop-types'
import { ScreenSliderContext } from 'domains/ScreenSlider/contexts/ScreenSlider/ScreenSliderContext'
import { useTransformScreenSlider } from 'domains/ScreenSlider/hooks'

const ScreenSliderProvider = (props) => {
  const { children } = props

  // [ADDITIONAL HOOKS]
  const initialScreenSliderData = useTransformScreenSlider()

  return (
    <ScreenSliderContext.Provider value={initialScreenSliderData}>
      {children}
    </ScreenSliderContext.Provider>
  )
}
ScreenSliderProvider.propTypes = {
  children: PropTypes.node
}
export default ScreenSliderProvider
