import { useTranslations } from '@qonsoll/translation'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { Text, TextArea, Title } from '~/components'

import useUpdateAppData from 'app/domains/App/hooks/post/useUpdateAppData'

const IdeaScreen = () => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { idea } = useAppDataContext()
  const { handleChangeIdea } = useUpdateAppData()

  // [HELPER_FUNCTIONS]

  return (
    <div className="row justify-content-center px-3">
      <div className="col-12 mb-4 mb-lg-5 d-lg-flex flex-lg-column align-items-lg-center">
        <Title as="h5" className="d-none d-lg-block">
          {t('Idea')}
        </Title>
        <Text secondary>
          {t('Describe your idea in as much detail as possible')}
        </Text>
      </div>
      <div className="col-12 col-lg-8 ">
        <TextArea
          value={idea}
          placeholder={`${t('Enter description here')}...`}
          onChange={handleChangeIdea}
        />
      </div>
    </div>
  )
}

export default IdeaScreen
