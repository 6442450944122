import PropTypes from 'prop-types'
import cn from 'classnames'
import { createElement } from 'react'
import styles from './Text.module.css'
import typographyStyles from '../../Typography/Typography.module.css'

const DEFAULT_TAG = 'p'
const DEFAULT_SIZE = 'body'

/**
 * It returns a React element with the appropriate className
 * @returns A function that takes in props and returns a React element.
 */
const Text = (props) => {
  const { children, size, style, className, secondary, center, ...rest } = props

  // [COMPUTED PROPERTIES]
  const tag = DEFAULT_TAG
  const cls = size || DEFAULT_SIZE

  return createElement(tag, {
    style,
    className: cn({
      [styles[cls]]: true,
      [className]: className,
      [typographyStyles['text-center']]: center,
      [typographyStyles['text-secondary']]: secondary
    }),
    dangerouslySetInnerHTML: { __html: children },
    ...rest
  })
}
Text.propTypes = {
  children: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  center: PropTypes.bool
}
export default Text
