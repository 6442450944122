import styled from 'styled-components'

const TextArea = styled.input`
  width: 100%;
  border: none;
  resize: none;
  display: flex;
  outline: none;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
  font-size: var(--booking-module-textarea-fs);
  line-height: var(--booking-module-textarea-lh);
  padding: var(--booking-module-textarea-padding);
  font-family: var(--booking-module-textarea-font-family);
  background-color: var(--booking-module-textarea-bg-color);
  border-radius: var(--booking-module-textarea-border-radius);
`
export default TextArea
