import { firestore } from '~/services/fb'
import { COLLECTIONS } from '~/__constants__'
import {
  collection,
  orderBy,
  query,
  startAt,
  Timestamp
} from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import moment from 'moment'

const useListenDays = ({
  meetingDay = moment().toISOString().replace(/T.*/gi, 'T00:00:00.000Z')
} = {}) =>
  useCollectionData(
    query(
      collection(firestore, COLLECTIONS.TIMESLOTS),
      orderBy('_createdAt'),
      startAt(Timestamp.fromDate(new Date(meetingDay)))
    )
  )

export default useListenDays
