import {
  useAppDataContext,
  useAppDataDispatch
} from 'app/domains/App/contexts/AppData/useAppData.js'
import { APP_DATA_REDUCER_CASES } from 'app/domains/App/__constants__'
import { EMAIL_REGEX } from '~/__constants__'

const useUpdateAppData = () => {
  // [ADDITIONAL_HOOKS]
  const appDataDispatch = useAppDataDispatch()
  const { email: appDataEmail, timeslots, dayPart } = useAppDataContext()

  // [HELPER_FUNCTIONS]
  // Logo
  const handleChangeLogo = (logo) => {
    appDataDispatch({
      type: APP_DATA_REDUCER_CASES.SET_LOGO,
      payload: logo
    })
  }
  // COLORS
  const handleChangeColor = (newColor) => {
    appDataDispatch({
      type: APP_DATA_REDUCER_CASES.SET_COLORS,
      payload: newColor
    })
  }

  // IDEA
  const handleChangeIdea = (e) => {
    appDataDispatch({
      type: APP_DATA_REDUCER_CASES.SET_IDEA,
      payload: e.target.value
    })
  }

  // STYLE
  const handleChangeStyle = (style) => {
    appDataDispatch({
      type: APP_DATA_REDUCER_CASES.SET_STYLE,
      payload: style
    })
  }

  //DAY PART
  const handleChangeDayPart = (dayPart) => {
    appDataDispatch({
      type: APP_DATA_REDUCER_CASES.SET_DAY_PART,
      payload: dayPart
    })
  }

  //Chosen day
  const handleChooseDayOfMeeting = (dayOfMeeting) => {
    appDataDispatch({
      type: APP_DATA_REDUCER_CASES.SET_DAY_OF_MEETING,
      payload: dayOfMeeting
    })
  }

  //Chosen day
  const handleChooseMeetingTime = (meetingTime, timeslotDayPart = dayPart) => {
    appDataDispatch({
      type: APP_DATA_REDUCER_CASES.SET_MEETING_TIME,
      payload: {
        meetingTime,
        forWhomMeetingIsBooked: timeslots[timeslotDayPart][meetingTime][0]
      }
    })
  }

  //Set calculator results
  const handleChangeCalculatorResults = (calculatorResults) => {
    appDataDispatch({
      type: APP_DATA_REDUCER_CASES.SET_CALCULATOR_RESULTS,
      payload: calculatorResults
    })
  }

  //Set result modal visibility
  const handleChangeResultModalVisible = (visible) => {
    appDataDispatch({
      type: APP_DATA_REDUCER_CASES.SET_RESULT_MODAL_VISIBLE,
      payload: visible
    })
  }

  //Set user email
  const handleChangeUserEmail = (e) => {
    const email = e.target.value
    if (new RegExp(EMAIL_REGEX).test(email)) {
      appDataDispatch({
        type: APP_DATA_REDUCER_CASES.SET_USER_EMAIL,
        payload: email
      })
    } else if (appDataEmail) {
      appDataDispatch({
        type: APP_DATA_REDUCER_CASES.SET_USER_EMAIL,
        payload: null
      })
    }
  }

  const handleIsEmailVisible = (value) => {
    appDataDispatch({
      type: APP_DATA_REDUCER_CASES.SET_IS_EMAIL_VISIBLE,
      payload: value
    })
  }
  const handleIsSuccessScreenVisible = (value) => {
    appDataDispatch({
      type: APP_DATA_REDUCER_CASES.SET_IS_SUCCESS_SCREEN_VISIBLE,
      payload: value
    })
  }

  return {
    handleChangeLogo,
    handleChangeColor,
    handleChangeStyle,
    handleChangeIdea,
    handleChangeDayPart,
    handleChooseDayOfMeeting,
    handleChooseMeetingTime,
    handleChangeCalculatorResults,
    handleChangeResultModalVisible,
    handleChangeUserEmail,
    handleIsEmailVisible,
    handleIsSuccessScreenVisible
  }
}

export default useUpdateAppData
