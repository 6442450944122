import { DAY_PARTS, PHASE_OF_DAY } from '~/__constants__'
import moment from 'moment'

const checkPhaseOfDay = () => {
  const currentTime = moment().get('hours')

  if (currentTime < PHASE_OF_DAY.MORNING_TIME) {
    return DAY_PARTS[0]
  } else if (currentTime < PHASE_OF_DAY.AFTERNOON_TIME) {
    return DAY_PARTS[1]
  } else {
    return DAY_PARTS[2]
  }
}

export default checkPhaseOfDay
