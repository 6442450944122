export const CALCULATOR_RESULT_SEARCH_PARAM = 'calculatorResultId'
export const COMMISSION_PARTNER_NAME_SEARCH_PARAM = 'ref'
export const ADVERTISEMENT_CAMPAIGN_ID_SEARCH_PARAM = 'adv'
export const IS_ADDITIONAL_STEPS_SHOW = 'isAdditionalStepsShow'
export const CUSTOMER_ID_SEARCH_PARAM = 'customerId'

export default {
  CALCULATOR_RESULT_SEARCH_PARAM,
  CUSTOMER_ID_SEARCH_PARAM,
  IS_ADDITIONAL_STEPS_SHOW
}
