import { useTranslations } from '@qonsoll/translation'
import { useMemo } from 'react'
import { Button, Text } from '~/components'
import { useIncreaseStatisticCounter } from '~/hooks'
import { useAppDataContext } from '../../contexts/AppData/useAppData'
import moment, { ISO_8601 } from 'moment'
import { outlook, google } from 'calendar-link'
import { createEvent } from 'ics'
import FileSaver from 'file-saver'
import { STATISTIC_COLLECTIONS } from '~/__constants__'

const AddToCalendarButtons = () => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { meetingTime } = useAppDataContext()
  const increaseStatistic = useIncreaseStatisticCounter()

  // [COMPUTED_PROPERTIES]
  const eventData = useMemo(
    () => ({
      title: t('Meeting with Senseteq'),
      description: t('You need to join Google Meet, find the link in email.')
    }),
    [t]
  )
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeRange = meetingTime.split('_')
  const [startEventTime, endEventTime] = timeRange

  // [HELPER_FUNCTIONS]
  const addToGoogle = () => {
    const googleUrl = new URL(
      google({
        ...eventData,
        start: startEventTime,
        end: moment(endEventTime).subtract(30, 'minutes')
      })
    )
    googleUrl.searchParams.append('ctz', userTimeZone)
    window.open(googleUrl.href, '_blank')
    increaseStatistic({
      field: STATISTIC_COLLECTIONS.SAVE_TO_CALENDAR_COUNT_FIELD,
      functionName: 'addToGoogle'
    })
    increaseStatistic({
      field: `totalSavedToGoogleCalendar`,
      functionName: 'addToGoogle'
    })
  }
  const addToApple = () => {
    const appleEvent = {
      ...eventData,
      start: moment(startEventTime)
        .format('YYYY-M-D-H-m')
        .split('-')
        .map((item) => +item),
      end: moment(endEventTime)
        .subtract(30, 'minutes')
        .format('YYYY-M-D-H-m')
        .split('-')
        .map((item) => +item),
      organizer: { name: 'Senseteq', email: 'hallo@senseteq.io' }
    }
    const { value } = createEvent(appleEvent)

    const appleInviteFile = new File([value], 'invite.ics', {
      type: 'text/calendar'
    })

    FileSaver.saveAs(appleInviteFile)
    increaseStatistic({
      field: STATISTIC_COLLECTIONS.SAVE_TO_CALENDAR_COUNT_FIELD,
      functionName: 'addToApple'
    })
    increaseStatistic({
      field: `totalSavedToAppleCalendar`,
      functionName: 'addToApple'
    })
  }
  const addToOutlook = () => {
    const outlookUrl = outlook({
      ...eventData,
      start: moment(startEventTime, ISO_8601).format('YYYYMMDDTHHmmSS'),
      end: moment(endEventTime, ISO_8601)
        .subtract(30, 'minutes')
        .format('YYYYMMDDTHHmmSS')
    })

    window.open(outlookUrl, '_blank')
    increaseStatistic({
      field: STATISTIC_COLLECTIONS.SAVE_TO_CALENDAR_COUNT_FIELD,
      functionName: 'addToOutlook'
    })
    increaseStatistic({
      field: `totalSavedToOutlookCalendar`,
      functionName: 'addToOutlook'
    })
  }

  return (
    <div className="row align-items-center justify-content-center mb-4">
      <div className="col-12">
        <Text center className="mb-2">
          {`${t('Add event to calendar')}:`}
        </Text>
      </div>
      <div className="col-auto">
        <Button
          variant="ghost"
          style={{ width: 40, height: 40, padding: 0 }}
          onClick={addToGoogle}>
          <img
            src="/icons/google.png"
            alt="google"
            style={{ width: 40, height: 40 }}
          />
        </Button>
      </div>
      <div className="col-auto">
        <Button
          variant="ghost"
          style={{ width: 40, height: 40, padding: 0 }}
          onClick={addToApple}>
          <img
            src="/icons/apple.png"
            alt="apple"
            style={{ width: 40, height: 40 }}
          />
        </Button>
      </div>
      <div className="col-auto">
        <Button
          variant="ghost"
          style={{ width: 40, height: 40, padding: 0 }}
          onClick={addToOutlook}>
          <img
            src="/icons/outlook.png"
            alt="outlook"
            style={{ width: 40, height: 40 }}
          />
        </Button>
      </div>
    </div>
  )
}

export default AddToCalendarButtons
