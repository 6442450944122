import { useTranslations } from '@qonsoll/translation'
import { useAppDataContext } from 'domains/App/contexts/AppData/useAppData'
import { useUpdateAppData } from 'domains/App/hooks'
import { Modal, Text, Title } from '~/components'
import AddToCalendarButtons from '../AddToCalendarButtons'

const ResultModal = () => {
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const { isShowResultModalVisible } = useAppDataContext()
  const { handleChangeResultModalVisible } = useUpdateAppData()

  // [HELPER_FUNCTIONS]
  const closeModal = () => {
    handleChangeResultModalVisible(false)
  }
  return (
    <Modal visible={isShowResultModalVisible} onClose={closeModal}>
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-9">
          <Title as="h2" center>
            {`🎉 ${t('Booked!')}`}
          </Title>
          <Text center className="mb-4">
            {t('Now you can add additional information about your project')}
          </Text>

          <Text center className="mb-2">
            {`${t(
              'Please check your e-mail now or call'
            )} <a href='tel:55181900' style='display: inline;font-weight: bold;text-decoration: inherit;color: inherit;'>55181900</a>`}
          </Text>
        </div>
        <AddToCalendarButtons />
      </div>
    </Modal>
  )
}

export default ResultModal
