import styled from 'styled-components'

const NavigationDotsStyled = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  z-index: 8888;
`

const NavigationDotStyled = styled.li`
  width: ${({ active }) =>
    active
      ? 'var(--booking-module-navigation-dot-width-active)'
      : 'var(--booking-module-navigation-dot-width)'};
  height: var(--booking-module-navigation-dot-height);
  border-radius: ${({ active }) =>
    active
      ? 'var(--booking-module-navigation-dot-border-radius-active)'
      : 'var(--booking-module-navigation-dot-border-radius)'};
  background-color: ${({ active }) =>
    active
      ? ' var(--booking-module-navigation-dot-active-bg)'
      : 'var(--booking-module-navigation-dot-bg)'};
  list-style: none;
  margin-left: 8px;
  cursor: pointer;
  @media screen and (max-width: 568px) {
    margin-left: 4px;
    height: 6px;
    width: ${({ active }) => (active ? '24px' : '6px')};
  }
`

export { NavigationDotStyled, NavigationDotsStyled }
